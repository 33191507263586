import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import Container from 'react-bootstrap/Container'
import Table from 'react-bootstrap/Table'
import StatusTable from '../../bars/StatusBar/StatusTable'
import AddUserForm from './AddUserForm'
import User from './User'
import LogMain from '../Logs/LogMain'
import LogGraphs from '../Logs/LogGraphs/LogGraphs'
import action from '../../redux/action'

const AdminPanel = () => {
  // Will be called by grand-children component to tell that they need re-rendering, and trigger useEffect()
  const [renderTrigger, setRenderTrigger] = useState(false)

  const [key, setKey] = useState('statusTable')

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(action.queryUserDb()).then()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [renderTrigger])

  const queryUserArr = useSelector((state) => {
    const arr = []
    state.userDb.users.forEach((doc) => {
      arr.push({ _id: doc._id })
    })
    // console.log(arr);
    return arr
  })

  return (
    <div className='AdminPanel'>
      <Container>
        <Tabs
          id='admin-tabs'
          activeKey={key}
          onSelect={(k) => setKey(k)}
        >
          <Tab tabClassName='tab-normal' eventKey='statusTable' title='Status'>
            <StatusTable/>
          </Tab>
          <Tab tabClassName='tab-normal' eventKey='graphs' title='Graphs'>
            <LogGraphs isActive={key === 'graphs'}/>
          </Tab>
          <Tab tabClassName='tab-normal' eventKey='logs' title='Logs'>
            <LogMain isActive={key === 'logs'}/>
          </Tab>
          <Tab tabClassName='tab-right' eventKey='manageUsers' title='Users'>
            <AddUserForm
              renderTrigger={renderTrigger}
              setRenderTrigger={(x) => setRenderTrigger(x)}
            />
            <Table size='sm' responsive striped>
              <thead>
              <tr>
                <th>Username</th>
                <th>Role</th>
                {/* <th>ID</th> */}
                <th>Action</th>
              </tr>
              </thead>
              <tbody>

              {
                queryUserArr.map((item) => (
                  <User
                    key={item._id}
                    _id={item._id}
                    renderTrigger={renderTrigger}
                    setRenderTrigger={(x) => setRenderTrigger(x)}
                  />
                ))
              }
              </tbody>
            </Table>
          </Tab>
        </Tabs>
      </Container>
    </div>
  )
}

export default AdminPanel
