// arrayBufferToBase64   is for converting Buffer in MongoDB into Base64 binary to display pictures
// query is for fetching pictures and info from backend -> MongoDb, and updating store
// setShowFilters for updating store (To toggle <ControlPanel>)
// setFilters for updating store (Components in <ControlPanel>)
// setKey is for Patching info of pictures, and updating store

import localStorageService from '../services/localStorageService'

const BACKEND_SOCKET = process.env.REACT_APP_BACKEND_SOCKET || 'http://localhost:3001'

// const arrayBufferToBase64 = (buffer) => {

//     let bytes = [].slice.call(new Uint8Array(buffer));

//     const binary = bytes.reduce((acc, b) => acc + String.fromCharCode(b), "");

//     return window.btoa(binary);
// }

// const query = (filters) => {
//   return async (dispatch) => {
//     const filterString = `startDate=${filters.startDate}&endDate=${filters.endDate}&isLiked=${filters.isLiked}&isHidden=${filters.isHidden}&isHuman=${filters.isHuman}&isWeapon=${filters.isWeapon}&isWildlife=${filters.isWildlife}&isDomestic=${filters.isDomestic}&tag1=${filters.tag1}&tag2=${filters.tag2}&tag3=${filters.tag3}&rasPiId=${filters.rasPiId}`
//
//     try {
//       const res = await fetch(`${BACKEND_SOCKET}/api/imgs/img_data?${filterString}`, {
//         method: 'get',
//         headers: {
//           'content-type': 'application/json;charset=UTF-8',
//           'Authorization': 'Bearer ' + localStorageService.getToken()
//         }
//       })
//       if (res.status === 200) {
//         const data = await res.json()
//
//         dispatch({
//           type: 'QUERY',
//           payload: data
//         })
//       } else if (res.status === 401) {
//         // console.log("Session timeout.");
//         await logout()
//         dispatch({
//           type: 'AUTHORIZATION_ERROR'
//         })
//       } else {
//         console.log('Error on something else.')
//         dispatch({
//           type: 'QUERY_ERROR'
//         })
//       }
//     } catch (err) {
//       dispatch({
//         type: 'QUERY_ERROR'
//       })
//       console.log(`error: ${err}`)
//     }
//   }
// }

// const queryGraph = (filters) => {
//   return async (dispatch) => {
//     const filterString = `startDate=${filters.startDate}&endDate=${filters.endDate}&isLiked=${filters.isLiked}&isHidden=${filters.isHidden}&isHuman=${filters.isHuman}&isWeapon=${filters.isWeapon}&isWildlife=${filters.isWildlife}&isDomestic=${filters.isDomestic}&tag1=${filters.tag1}&tag2=${filters.tag2}&tag3=${filters.tag3}&rasPiId=${filters.rasPiId}`
//
//     try {
//       const res = await fetch(`${BACKEND_SOCKET}/api/graphs/graph_data?${filterString}`, {
//         method: 'get',
//         headers: {
//           'content-type': 'application/json;charset=UTF-8',
//           'Authorization': 'Bearer ' + localStorageService.getToken()
//         }
//       })
//       if (res.status === 200) {
//         const data = await res.json()
//
//         dispatch({
//           type: 'QUERY_GRAPH',
//           payload: data
//         })
//       } else if (res.status === 401) {
//         // console.log("Session timeout.");
//         logout()
//
//         dispatch({
//           type: 'AUTHORIZATION_ERROR'
//         })
//       } else {
//         console.log('Error on something else.')
//         dispatch({
//           type: 'QUERY_ERROR'
//         })
//       }
//     } catch (err) {
//       dispatch({
//         type: 'QUERY_ERROR'
//       })
//       console.log(`error: ${err}`)
//     }
//   }
// }

const setShowFilters = (flag) => {
  return (dispatch) => {
    dispatch({
      type: 'SET_SHOW_FILTERS',
      payload: flag
    })
  }
}

const setDateButtons = (flag) => {
  return (dispatch) => {
    dispatch({
      type: 'SET_DATE_BUTTONS',
      payload: flag
    })
  }
}

const setFilter = (filter, parameter) => {
  let dispatchObj = { payload: parameter }

  switch (filter) {
    case 'startDate':
      dispatchObj = { ...dispatchObj, type: 'SET_FILTER_START_DATE' }
      break
    case 'endDate':
      dispatchObj = { ...dispatchObj, type: 'SET_FILTER_END_DATE' }
      break
    case 'isLiked':
      dispatchObj = { ...dispatchObj, type: 'SET_FILTER_IS_LIKED' }
      break
    case 'isHidden':
      dispatchObj = { ...dispatchObj, type: 'SET_FILTER_IS_HIDDEN' }
      break
    case 'isHuman':
      dispatchObj = { ...dispatchObj, type: 'SET_FILTER_IS_HUMAN' }
      break
    case 'isWeapon':
      dispatchObj = { ...dispatchObj, type: 'SET_FILTER_IS_WEAPON' }
      break
    case 'isWildlife':
      dispatchObj = { ...dispatchObj, type: 'SET_FILTER_IS_WILDLIFE' }
      break
    case 'isDomestic':
      dispatchObj = { ...dispatchObj, type: 'SET_FILTER_IS_DOMESTIC' }
      break
    case 'tag1':
      dispatchObj = { ...dispatchObj, type: 'SET_FILTER_TAG1' }
      break
    case 'tag2':
      dispatchObj = { ...dispatchObj, type: 'SET_FILTER_TAG2' }
      break
    case 'tag3':
      dispatchObj = { ...dispatchObj, type: 'SET_FILTER_TAG3' }
      break
    case 'rasPiId':
      dispatchObj = { ...dispatchObj, type: 'SET_FILTER_RASPIID' }
      break
    default:
      return
  }

  return (dispatch) => {
    dispatch(dispatchObj)
  }
}

const setKey = (key, id, flag) => {
  return async (dispatch) => {
    try {
      let bodyObj = { id: id }
      let dispatchObj = { payload: { id: id, flag: flag } }

      switch (key) {
        case 'isLiked':
          bodyObj = { ...bodyObj, isLiked: flag }
          dispatchObj = { ...dispatchObj, type: 'SET_IS_LIKED' }
          break
        case 'isHidden':
          bodyObj = { ...bodyObj, isHidden: flag }
          dispatchObj = { ...dispatchObj, type: 'SET_IS_HIDDEN' }
          break
        case 'isHuman':
          bodyObj = { ...bodyObj, isHuman: flag }
          dispatchObj = { ...dispatchObj, type: 'SET_IS_HUMAN' }
          break
        case 'isWeapon':
          bodyObj = { ...bodyObj, isWeapon: flag }
          dispatchObj = { ...dispatchObj, type: 'SET_IS_WEAPON' }
          break
        case 'isWildlife':
          bodyObj = { ...bodyObj, isWildlife: flag }
          dispatchObj = { ...dispatchObj, type: 'SET_IS_WILDLIFE' }
          break
        case 'isDomestic':
          bodyObj = { ...bodyObj, isDomestic: flag }
          dispatchObj = { ...dispatchObj, type: 'SET_IS_DOMESTIC' }
          break
        case 'tag1':
          bodyObj = { ...bodyObj, tag1: flag }
          dispatchObj = { ...dispatchObj, type: 'SET_TAG1' }
          break
        case 'tag2':
          bodyObj = { ...bodyObj, tag2: flag }
          dispatchObj = { ...dispatchObj, type: 'SET_TAG2' }
          break
        case 'tag3':
          bodyObj = { ...bodyObj, tag3: flag }
          dispatchObj = { ...dispatchObj, type: 'SET_TAG3' }
          break
        default:
          return
      }

      const res = await fetch(`${BACKEND_SOCKET}/api/imgs/img_data`,
        {
          method: 'PATCH',
          body: JSON.stringify(bodyObj),
          headers: {
            'content-type': 'application/json;charset=UTF-8',
            'Authorization': 'Bearer ' + localStorageService.getToken()
          }
        }
      )
      if (res.status === 200) {
        // console.log(dispatchObj);
        dispatch(dispatchObj)
      } else if (res.status === 401) {
        // console.log("Session timeout.");
        // logout();

        dispatch({
          type: 'AUTHORIZATION_ERROR'
        })
      } else {
        console.log('Error on something else.')
      }
    } catch (err) {
      console.log(`error: ${err}`)
    }
  }
}

const logout = () => {
  return async (dispatch) => {
    localStorageService.removeToken()

    dispatch({
      type: 'LOGOUT'
    })
  }
}

const queryUserDb = () => {
  return async (dispatch) => {
    try {
      const res = await fetch(`${BACKEND_SOCKET}/api/users/user_data`, {
        method: 'get',
        headers: {
          'content-type': 'application/json;charset=UTF-8',
          'Authorization': 'Bearer ' + localStorageService.getToken()
        }
      })
      if (res.status === 200) {
        const data = await res.json()

        // console.log(data);

        dispatch({
          type: 'QUERY_USER_DB',
          payload: data
        })
      } else if (res.status === 401) {
        // console.log("Session timeout.");
        logout()

        dispatch({
          type: 'AUTHORIZATION_ERROR'
        })
      } else {
        console.log('Error on something else.')
      }
    } catch (err) {
      console.log(`error: ${err}`)
    }
  }
}

const createUser = (formData) => {
  return async (dispatch) => {
    try {
      const res = await fetch(`${BACKEND_SOCKET}/api/users/create`, {
        method: 'post',
        headers: {
          'content-type': 'application/json;charset=UTF-8',
          'Authorization': 'Bearer ' + localStorageService.getToken()
        },
        body: JSON.stringify(formData)
      })
      if (res.status === 201) {
        const result = await res.text()

        if (result.newUser) {
          dispatch({
            type: 'ADD_USER',
            payload: JSON.parse(result.newUser)
          })
        }
      } else if (res.status === 401) {
        // console.log("Session timeout.");

        logout()

        dispatch({
          type: 'AUTHORIZATION_ERROR'
        })
      } else {
        console.log('Error on something else.')
      }
    } catch (err) {
      console.log(`error: ${err}`)
    }
  }
}

const deleteUser = (id) => {
  return async (dispatch) => {
    try {
      const res = await fetch(`${BACKEND_SOCKET}/api/users/${id}`, {
        method: 'delete',
        headers: {
          'content-type': 'application/json;charset=UTF-8',
          'Authorization': 'Bearer ' + localStorageService.getToken()
        }
      })
      if (res.status === 200) {
        await res.json()

        // console.log(data);

        dispatch({
          type: 'DELETE_USER',
          payload: id
        })
      } else if (res.status === 401) {
        // console.log("Session timeout.");

        logout()

        dispatch({
          type: 'AUTHORIZATION_ERROR'
        })
      } else {
        console.log('Error on something else.')
      }
    } catch (err) {
      console.log(`error: ${err}`)
    }
  }
}

const queryRasPiName = () => {
  return async (dispatch) => {
    try {
      const res = await fetch(`${BACKEND_SOCKET}/api/users/rasPi_names`, {
        method: 'get',
        headers: {
          'content-type': 'application/json;charset=UTF-8',
          'Authorization': 'Bearer ' + localStorageService.getToken()
        }
      })
      if (res.status === 200) {
        const data = await res.json()

        // console.log(data);

        dispatch({
          type: 'QUERY_RASPI_NAME',
          payload: data
        })
      } else if (res.status === 401) {
        // console.log("Session timeout.");
        logout()

        dispatch({
          type: 'AUTHORIZATION_ERROR'
        })
      } else {
        console.log('Error on something else.')
      }
    } catch (err) {
      console.log(`error: ${err}`)
    }
  }
}

/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default {
  // query,
  // queryLatest,
  // queryGraph,
  queryUserDb,
  setShowFilters,
  setDateButtons,
  setFilter,
  setKey,
  logout,
  createUser,
  deleteUser,
  queryRasPiName
}
