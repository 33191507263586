import React from 'react'
import PropTypes from 'prop-types'
import Badge from 'react-bootstrap/Badge'
import Table from 'react-bootstrap/Table'

// an amount of hours before the badge turns yellow.
const IMG_LAST_SEEN_HOURS_NORMAL = Number(process.env.REACT_APP_IMG_LAST_SEEN_HOURS_NORMAL) || 24
// an amount of hours before the badge turns red.
const IMG_LAST_SEEN_HOURS_WARNING = Number(process.env.REACT_APP_IMG_LAST_SEEN_HOURS_WARNING) || 48

const ImageStatus = ({ rxTime }) => {
  return (
    <Table size='sm' borderless>
      <tbody>
        <tr>
          <td height='33px' />
        </tr>
        {/* rx */}
        <tr>
          <td>
            <Badge className={`${(rxTime === 1) ? 'd-inline status-badge-green' : 'd-none'}`}>&lt;{IMG_LAST_SEEN_HOURS_NORMAL}H</Badge>
            <Badge className={`${(rxTime === 2) ? 'd-inline status-badge-yellow' : 'd-none'}`}>&lt;{IMG_LAST_SEEN_HOURS_WARNING}H</Badge>
            <Badge className={`${(rxTime === 3) ? 'd-inline status-badge-orange' : 'd-none'}`}>&gt;{IMG_LAST_SEEN_HOURS_WARNING}H</Badge>
          </td>
        </tr>
      </tbody>
    </Table>
  )
}

ImageStatus.defaultProps = {
  rxTime: IMG_LAST_SEEN_HOURS_WARNING
}

ImageStatus.propTypes = {
  rxTime: PropTypes.number
}

export default ImageStatus
