import localStorageService from '../services/localStorageService'

const DAY_RANGE = Number(process.env.REACT_APP_DAY_RANGE) || 7

const startDate = new Date()
const now = new Date()

const initialState = {
  showFilters: false,
  filters: {
    startDate: new Date(startDate.setDate(now.getDate() - DAY_RANGE)),
    endDate: now,
    isLiked: false,
    isHidden: false,
    isHuman: false,
    isWeapon: false,
    isWildlife: false,
    isDomestic: false,
    tag1: false,
    tag2: false,
    tag3: false,
    rasPiId: 'all'
  },
  dateButtons: 1,
  query: {
    data: [],
    lastQueryTime: now
  },
  // queryLatest: {
  //     data: [],
  //     lastQueryTime: now
  // },
  // queryGraph: {
  //   data: [],
  //   lastQueryTime: now
  // },
  queryError: {
    count: 0
  },
  user: localStorageService.getUserFromToken(),
  loginError: {
    status: false,
    message: ''
  },
  userDb: {
    users: []
  },
  authorizationError: {
    status: false
  },
  rasPis: {
    rasPiIds: []
  }
}

const reducer = (state = initialState, action) => {
  const findDocIndexById = (id) => {
    return state.query.data.findIndex((doc) => doc._id === id)
  }

  let newState
  let newFilters

  switch (action.type) {
    case 'QUERY':
      newState = {
        ...state,
        query: {
          ...state.query,
          data: [...action.payload],
          lastQueryTime: new Date()
        },
        queryError: {
          ...state.queryError,
          count: 0
        }
      }
      return newState
    // case 'QUERY_LATEST':
    //   newState = {
    //     ...state,
    //     // queryLatest: {
    //     //     ...state.queryLatest,
    //     //     data: [...action.payload],
    //     //     lastQueryTime: new Date()
    //     // },
    //     queryError: {
    //       ...state.queryError,
    //       count: 0
    //     }
    //   }
    //   return newState
    // case 'QUERY_GRAPH':
    //   newState = {
    //     ...state,
    //     queryGraph: {
    //       ...state.queryGraph,
    //       data: [...action.payload],
    //       lastQueryTime: new Date()
    //     },
    //     queryError: {
    //       ...state.queryError,
    //       count: 0
    //     }
    //   }
    //   return newState
    case 'QUERY_SUCCESS':
      newState = {
        ...state,
        query: {
          ...state.query,
          lastQueryTime: new Date()
        },
        queryError: {
          ...state.queryError,
          count: 0
        }
      }
      return newState
    case 'QUERY_ERROR':
      newState = {
        ...state,
        queryError: {
          ...state.queryError,
          count: state.queryError.count + 1
        }
      }
      return newState
    case 'QUERY_USER_DB':
      newState = {
        ...state,
        userDb: {
          ...state.userDb,
          users: [...action.payload]
        }
      }
      return newState
    case 'QUERY_RASPI_NAME':

      action.payload.forEach((element) => {
        newFilters = {
          ...newFilters,
          [element]: false
        }
      })

      newFilters = {
        ...newFilters,
        all: false
      }

      newState = {
        ...state,
        rasPis: {
          ...state.rasPis,
          rasPiIds: [...action.payload]
        },
        filters: {
          ...state.filters,
          ...newFilters
        }
      }

      return newState
    case 'SET_FILTER_STRING':
      newState = {
        ...state,
        filterString: action.payload
      }
      return newState
    case 'SET_SHOW_FILTERS':
      newState = {
        ...state,
        showFilters: action.payload
      }
      return newState
    case 'SET_DATE_BUTTONS':
      newState = {
        ...state,
        dateButtons: action.payload
      }
      return newState
    case 'SET_FILTER_START_DATE':
      newFilters = {
        ...state.filters,
        startDate: action.payload
      }
      newState = {
        ...state,
        filters: newFilters
      }
      return newState
    case 'SET_FILTER_END_DATE':
      newFilters = {
        ...state.filters,
        endDate: action.payload
      }
      newState = {
        ...state,
        filters: newFilters
      }
      return newState
    case 'SET_FILTER_IS_LIKED':
      newFilters = {
        ...state.filters,
        isLiked: action.payload
      }
      newState = {
        ...state,
        filters: newFilters
      }
      return newState
    case 'SET_FILTER_IS_HIDDEN':
      newFilters = {
        ...state.filters,
        isHidden: action.payload
      }
      newState = {
        ...state,
        filters: newFilters
      }
      return newState
    case 'SET_FILTER_IS_HUMAN':
      newFilters = {
        ...state.filters,
        isHuman: action.payload
      }
      newState = {
        ...state,
        filters: newFilters
      }
      return newState
    case 'SET_FILTER_IS_WEAPON':
      newFilters = {
        ...state.filters,
        isWeapon: action.payload
      }
      newState = {
        ...state,
        filters: newFilters
      }
      return newState
    case 'SET_FILTER_IS_WILDLIFE':
      newFilters = {
        ...state.filters,
        isWildlife: action.payload
      }
      newState = {
        ...state,
        filters: newFilters
      }
      return newState
    case 'SET_FILTER_IS_DOMESTIC':
      newFilters = {
        ...state.filters,
        isDomestic: action.payload
      }
      newState = {
        ...state,
        filters: newFilters
      }
      return newState
    case 'SET_FILTER_TAG1':
      newFilters = {
        ...state.filters,
        tag1: action.payload
      }
      newState = {
        ...state,
        filters: newFilters
      }
      return newState
    case 'SET_FILTER_TAG2':
      newFilters = {
        ...state.filters,
        tag2: action.payload
      }
      newState = {
        ...state,
        filters: newFilters
      }
      return newState
    case 'SET_FILTER_TAG3':
      newFilters = {
        ...state.filters,
        tag3: action.payload
      }
      newState = {
        ...state,
        filters: newFilters
      }
      return newState
    case 'SET_IS_LIKED':
      state.query.data[findDocIndexById(action.payload.id)].isLiked = action.payload.flag
      return state
    case 'SET_IS_HIDDEN':
      state.query.data[findDocIndexById(action.payload.id)].isHidden = action.payload.flag
      return state
    case 'SET_IS_HUMAN':
      state.query.data[findDocIndexById(action.payload.id)].isHuman = action.payload.flag
      return state
    case 'SET_IS_WEAPON':
      state.query.data[findDocIndexById(action.payload.id)].isWeapon = action.payload.flag
      return state
    case 'SET_IS_WILDLIFE':
      state.query.data[findDocIndexById(action.payload.id)].isWildlife = action.payload.flag
      return state
    case 'SET_IS_DOMESTIC':
      state.query.data[findDocIndexById(action.payload.id)].isDomestic = action.payload.flag
      return state
    case 'SET_TAG1':
      state.query.data[findDocIndexById(action.payload.id)].tag1 = action.payload.flag
      return state
    case 'SET_TAG2':
      state.query.data[findDocIndexById(action.payload.id)].tag2 = action.payload.flag
      return state
    case 'SET_TAG3':
      state.query.data[findDocIndexById(action.payload.id)].tag3 = action.payload.flag
      return state
    case 'LOGIN':
      newState = {
        ...state,
        user: action.payload,
        loginError: {
          ...state.loginError,
          status: false,
          message: ''
        },
        authorizationError: {
          ...state.authorizationError,
          status: state.authorizationError.status = false
        }
      }
      return newState
    case 'LOGIN_ERROR':
      newState = {
        ...state,
        loginError: {
          ...state.loginError,
          status: true,
          message: action.payload
        }
      }
      return newState
    case 'LOGOUT':
      newState = {
        ...state,
        user: localStorageService.getUserFromToken(),
        loginError: {
          ...state.loginError,
          status: false,
          message: ''
        }
        // authorizationError: {
        //     ...state.authorizationError,
        //     status: state.authorizationError.status = false
        // }
      }
      return newState
    case 'AUTHORIZATION_ERROR':
      newState = {
        ...state,
        authorizationError: {
          ...state.authorizationError,
          status: state.authorizationError.status = true
        }
      }
      return newState
    case 'ADD_USER':
      newState = {
        ...state,
        userDb: {
          ...state.userDb,
          users: [...state.userDb.users, action.payload]
        }
      }
      return newState
    case 'DELETE_USER':
      newState = {
        ...state,
        userDb: {
          ...state.userDb,
          users: state.userDb.users.filter((item) => item !== action.payload)
        }
      }
      return newState
    case 'SET_FILTER_RASPIID':
      newFilters = {
        ...state.filters,
        rasPiId: action.payload
      }
      newState = {
        ...state,
        filters: newFilters
      }
      return newState
    default:
      return state
  }
}

export default reducer
