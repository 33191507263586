import React from 'react'
import { Switch, Route } from 'react-router-dom'
import FeedMain from './pages/FeedMain'
import StatsMain from './pages/StatsMain'
import AdminMain from './pages/AdminMain'
import Login from './pages/Login'

const Main = (props) => {
  return (
    <>
      <Switch>
        <Route path='/' exact render={() => <FeedMain />} />
        <Route path='/stats' render={() => <StatsMain />} />
        <Route path='/admin' render={() => <AdminMain />} />
        <Route path='/login' render={() => <Login />} />
        <Route render={() => <div>404 Not Found</div>} />
      </Switch>
    </>
  )
}
export default Main
