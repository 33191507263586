import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import action from '../../redux/action'
import PosterLatest from './Latest/PosterLatest'
import localStorageService from '../../services/localStorageService'

const FeedLatest = (props) => {
  const BACKEND_SOCKET = process.env.REACT_APP_BACKEND_SOCKET || 'http://localhost:3001'

  // data fetching cycle in milliseconds
  const RELOAD_MSEC = process.env.REACT_APP_RELOAD_MSEC || 6e4

  const dispatch = useDispatch()

  const [latestQueryArr, setLatestQueryArr] = useState([])
  const [rasPiDataObj, setRasPiDataObj] = useState({ undefined: [] })

  useEffect(() => {
    if (!props.isActive) {
      return
    }

    let timer

    // fetch latest image
    const fetcher = async () => {
      try {
        const res = await fetch(`${BACKEND_SOCKET}/api/imgs/img_latest`, {
          method: 'get',
          headers: {
            'content-type': 'application/json;charset=UTF-8',
            'Authorization': 'Bearer ' + localStorageService.getToken()
          }
        })
        if (res.status === 200) {
          const data = await res.json()

          const dbArr = []

          data.forEach((d) => {
            if (d?.h6 === undefined) d.h6 = 0
            if (d?.h12 === undefined) d.h12 = 0
            if (d?.h18 === undefined) d.h18 = 0
            if (d?.h24 === undefined) d.h24 = 0

            dbArr.push(
              {
                ...d,
                rasPiId: d._id,
                _id: d.docId,
                img: d.img
              }
            )
          })

          // console.log(dbArr)

          setLatestQueryArr(dbArr)

          dispatch({
            type: 'QUERY_LATEST'
          })
        } else if (res.status === 401) {
          // console.log("Session timeout.");

          dispatch({
            type: 'AUTHORIZATION_ERROR'
          })

          dispatch(action.logout()).then(() => {
            return () => clearTimeout(timer)
          })

        } else {
          console.log('Error on something else.')
          dispatch({
            type: 'QUERY_ERROR'
          })
        }
      } catch (err) {
        console.log(`error: ${err}`)
        dispatch({
          type: 'QUERY_ERROR'
        })
      }

      timer = setTimeout(() => fetcher(), RELOAD_MSEC)
    }

    const rasPiDataFetcher = async () => {
      try {
        const res = await fetch(`${BACKEND_SOCKET}/api/users/rasPi_data`, {
          method: 'get',
          headers: {
            'content-type': 'application/json;charset=UTF-8',
            'Authorization': 'Bearer ' + localStorageService.getToken()
          }
        })
        if (res.status === 200) {
          const data = await res.json()

          // console.log(data)

          setRasPiDataObj(data)
        } else if (res.status === 401) {
          dispatch({
            type: 'AUTHORIZATION_ERROR'
          })
          dispatch(action.logout()).then(() => {
            return () => clearTimeout(timer)
          })

        } else {
          console.log('else')
        }
      } catch (err) {
        console.log(`error: ${err}`)
      }
    }

    fetcher().then()
    rasPiDataFetcher().then()

    return () => clearTimeout(timer)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isActive])

  return (

    <Container fluid>
      <Row>
        {
          latestQueryArr.map((item) => (
            <Col key={item._id} xs={12} md={6} xl={4}>
              <PosterLatest
                _id={item._id}
                camId={item.camId}
                createdAt={item.createdAt}
                docId={item.docId}
                fileName={item.filename}
                h6={item.h6}
                h12={item.h12}
                h18={item.h18}
                h24={item.h24}
                img={item.img}
                rasPiId={item.rasPiId}
                sentAt={item.sentAt}
                updatedAt={item.updatedAt}
                rasPiData={rasPiDataObj[item.camId][0]}
              />
            </Col>
          ))
        }
      </Row>
    </Container>
  )
}

export default FeedLatest
