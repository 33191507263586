import { library } from '@fortawesome/fontawesome-svg-core'
import { faHeart as faHeartSolid, faTrashAlt as faTrashAltSolid, faStar as faStarSolid, faExclamationTriangle as faExclamationTriangleSolid } from '@fortawesome/free-solid-svg-icons'
import { faHeart as faHeartRegular, faTrashAlt as faTrashAltRegular, faStar as faStarRegular } from '@fortawesome/free-regular-svg-icons'

library.add(
  faHeartSolid, faHeartRegular,
  faTrashAltSolid, faTrashAltRegular,
  faStarSolid, faStarRegular,
  faExclamationTriangleSolid
)
