import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Badge from 'react-bootstrap/Badge'
import action from '../../redux/action'

const RasPiBar = (props) => {
  const rasPiIdSelector = useSelector((state) => state.rasPis.rasPiIds)
  const filtersSelector = useSelector((state) => state.filters)

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(action.queryRasPiName())

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <div className='d-flex justify-content-start'>
        {
          rasPiIdSelector.map((id) => {
            return (
              <Badge
                key={id}
                className={`mr-1 clickable ${(filtersSelector.rasPiId === id.toString() ? 'rasPiId' : '')}`}
                id={id}
                variant='secondary'
                onClick={() => dispatch(action.setFilter('rasPiId', id.toString()))}
              >
                <span className='h6'>{id}</span>
              </Badge>
            )
          })
        }
        <Badge
          className={`mr-1 clickable ${(filtersSelector.rasPiId === 'all' ? 'rasPiId' : '')}`}
          id='all'
          variant='secondary'
          onClick={() => dispatch(action.setFilter('rasPiId', 'all'))}
        >
          <span className='h6'>All</span>
        </Badge>
      </div>
    </>
  )
}

export default RasPiBar
