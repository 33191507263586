import { useSelector } from 'react-redux'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Logs from './Logs/Logs'
import Login from '../Login'

const LogMain = (props) => {
  const userSelector = useSelector((state) => state.user)

  return (
    <Container fluid>
      <Row>
        {
                    userSelector?.role === 'admin'
                      ? <Logs isActive={props.isActive} />
                      : <Login onlyAdmin />
                }
      </Row>
    </Container>
  )
}

export default LogMain
