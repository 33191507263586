import React, { useState } from 'react'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import Badge from 'react-bootstrap/Badge'
import Collapse from 'react-bootstrap/Collapse'

const PosterLatest = (props) => {
  const slots = [props.h6, props.h12 - props.h6, props.h18 - props.h12, props.h24 - props.h18]
  const [showInfo, setShowInfo] = useState(false)

  return (
    <>
      <Card className='mt-2 mx-auto' style={{ width: 'auto' }}>
        {/* <Card.Header className="d-flex justify-content-between">
                </Card.Header> */}
        <Card.Img variant='top' src={props.img} style={{ height: 'auto' }}/>
        <Card.Body>
          <Card.Title>UNDP{props.rasPiId} - Camera: {props.camId}</Card.Title>
          <Card.Text>File name: {props.fileName}</Card.Text>
          <Card.Text className='text-success font-weight-bold'>Sent
            at: {new Date(props.sentAt).toLocaleString('en-GB')}
          </Card.Text>
          {/* <Card.Text>Created at: {new Date(props.createdAt).toLocaleString('en-GB')}</Card.Text>
                    <Card.Text>Updated at: {new Date(props.updatedAt).toLocaleString('en-GB')}</Card.Text> */}
        </Card.Body>
        <Card.Footer>
          {
            (props.h24 === 0)
              ? <Card.Text className='mb-1 text-primary'>No new images in past 24 hours.</Card.Text>
              : <>
                <Card.Text className='mb-1'>Uploaded images between intervals of past 24
                  hours:
                </Card.Text>
                <Button className='mr-1' variant={(slots[0] > 0) ? 'info' : 'secondary'} size='sm'>
                  0 - 6 hrs <Badge variant='light'>{slots[0]}</Badge>
                </Button>
                <Button className='mr-1' variant={(slots[1] > 0) ? 'info' : 'secondary'} size='sm'>
                  6 - 12 hrs <Badge variant='light'>{slots[1]}</Badge>
                </Button>
                <Button className='mr-1' variant={(slots[2] > 0) ? 'info' : 'secondary'} size='sm'>
                  12 - 18 hrs <Badge variant='light'>{slots[2]}</Badge>
                </Button>
                <Button className='mr-1' variant={(slots[3] > 0) ? 'info' : 'secondary'} size='sm'>
                  18 - 24 hrs <Badge variant='light'>{slots[3]}</Badge>
                </Button>
              </>
          }
        </Card.Footer>
        <Card.Body>
          <Button
            variant="outline-success"
            active={showInfo}
            onClick={() => setShowInfo(!showInfo)}
            aria-controls="collapse-info"
            aria-expanded={showInfo}
          >
            {showInfo ? 'Less info' : 'More info'}
          </Button>
          <Collapse in={showInfo} mountOnEnter unmountOnExit>
            <div id="collapse-info">
              <Card.Text className="mt-3 mb-2">Label: {props.rasPiData?.label}</Card.Text>
              <Card.Text className="mb-1">{`UNDP${props.rasPiId}TX`}</Card.Text>
              <Card.Img src={props.rasPiData.imgTx}/>
              <Card.Text className="mt-3 mb-1">{`UNDP${props.rasPiId}RX`}</Card.Text>
              <Card.Img src={props.rasPiData.imgRx}/>
            </div>
          </Collapse>
        </Card.Body>
      </Card>
    </>
  )
}
export default PosterLatest
