import React, { useRef, useState, useEffect } from 'react'
import EnvTemperature from './EnvTemperature'

const EnvTemperatureWrapper = (props) => {
  const chartArea = useRef(null)
  const [chart, setChart] = useState(null)

  useEffect(() => {
    if (!chart) {
      setChart(new EnvTemperature(chartArea.current, props.data))
    } else {
      chart.update(props.data)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chart, props.data])

  return (
    <div className='envTemperature-area' ref={chartArea} />
  )
}

export default EnvTemperatureWrapper
