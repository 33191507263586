import React, { useState, useRef } from 'react'
import { useDispatch } from 'react-redux'
import Button from 'react-bootstrap/Button'
import Overlay from 'react-bootstrap/Overlay'
import Tooltip from 'react-bootstrap/Tooltip'
import Collapse from 'react-bootstrap/Collapse'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import localStorageService from '../../services/localStorageService'
import action from '../../redux/action'
import RasPiForm from './RasPiForm'

const BACKEND_SOCKET = process.env.REACT_APP_BACKEND_SOCKET || 'http://localhost:3001'

const UserActions = (props) => {
  // const [willEdit, setWillEdit] = useState(false);
  const [willDelete, setWillDelete] = useState(false)
  const [willJwt, setWillJwt] = useState(false)
  const [jwt, setJwt] = useState()
  const [showOverlay, setShowOverlay] = useState(false)
  const [showRasPiForm, setShowRasPiForm] = useState(false)

  const target = useRef(null)

  const dispatch = useDispatch()

  const deleteUser = () => {
    dispatch(action.deleteUser(props._id)).then(() => {
        props.setRenderTrigger(!props.renderTrigger)
      }
    )
  }

  const getJwt = async () => {
    try {
      const res = await fetch(`${BACKEND_SOCKET}/api/users/getJwt/${props._id}`, {
        method: 'get',
        headers: {
          'content-type': 'application/json;charset=UTF-8',
          'Authorization': 'Bearer ' + localStorageService.getToken()
        }
      })
      if (res.status === 200) {
        const data = await res.json()

        setJwt(data.token)

        setWillJwt(true)
      } else if (res.status === 401) {

        dispatch({
          type: 'AUTHORIZATION_ERROR'
        })

        props.setRenderTrigger(!props.renderTrigger)

      } else {
        console.log('else')
      }
    } catch (err) {
      console.log(`error: ${err}`)
    }
  }

  return (
    <div className='UserActions'>
      <div className='d-flex flex- mx-3'>
        {
          (props.username !== 'admin') &&
          !willDelete &&
          <Button
            className='mr-1'
            size='sm' variant='outline-danger'
            onClick={() => setWillDelete(true)}
          >
            Delete
          </Button>
        }
        {willDelete &&
        <Button
          className='mr-1'
          size='sm' variant='danger'
          onClick={() => deleteUser()}
        >
          Confirm Delete
        </Button>}
        {
          (props.role !== 'rasPi') &&
          <Button
            className='mr-1'
            size='sm'
            variant='outline-warning'
          >
            Change Password
          </Button>
        }
        {
          (props.role === 'rasPi') &&
          !willJwt &&
          <Button
            className='mr-1'
            size='sm'
            variant='outline-info'
            onClick={() => getJwt()}
          >
            Generate JWT
          </Button>
        }
        {
          willJwt &&
          <CopyToClipboard text={jwt} onCopy={() => setShowOverlay(true)}>
            <Button
              size='sm'
              variant='info'
              ref={target}
            >
              Copy JWT
            </Button>
          </CopyToClipboard>
        }
        <Overlay
          target={target.current}
          show={showOverlay}
          placement='right'
          rootClose
          onHide={() => setShowOverlay(false)}
        >
          <Tooltip id='overlay'>
            Copied to clipboard
          </Tooltip>
        </Overlay>
        {
          (props.role === 'rasPi') &&
          <Button
            size='sm'
            variant='outline-success'
            onClick={() => setShowRasPiForm(!showRasPiForm)}
            aria-controls="collapse-rasPiForm"
            aria-expanded={showRasPiForm}
          >
            {
              (showRasPiForm) ? 'Hide Label & Thumbnails' : 'Edit Label & Thumbnails'
            }
          </Button>
        }
      </div>
      <div>
        {
          (props.role === 'rasPi') &&
          <Collapse in={showRasPiForm} mountOnEnter={true} unmountOnExit={true}>
            <div id="collapse-rasPiForm">
              <RasPiForm
                // role={props.role}
                username={props.username}
              />
            </div>
          </Collapse>
        }
      </div>
    </div>
  )
}

export default UserActions
