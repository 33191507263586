import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import localStorageService from '../services/localStorageService'

const BACKEND_SOCKET = process.env.REACT_APP_BACKEND_SOCKET || 'http://localhost:3001'

const Login = (props) => {
  const [formState, setFormState] = useState(
    {
      formElements: {
        username: {
          type: 'text',
          value: '',
          validator: {
            required: true
            // minLength: process.env.USERNAME_MIN_LENGTH || 4,
            // maxLength: process.env.USERNAME_MAX_LENGTH || 8
          },
          // touched: false,
          touched: true,
          error: { status: true, message: '' }
        },
        password: {
          type: 'password',
          value: '',
          validator: {
            required: true
            // minLength: process.env.PASSWORD_MIN_LENGTH || 4,
            // pattern: 'password'
          },
          // touched: false,
          touched: true,
          error: { status: true, message: '' }
        }
      },
      formValid: false
    }
  )

  const dispatch = useDispatch()

  const userSelector = useSelector((state) => state.user)
  const loginErrorSelector = useSelector((state) => state.loginError)

  const history = useHistory()

  const onFormChange = (e) => {
    const name = e.target.name
    const value = e.target.value
    const updatedForm = { ...formState.formElements }
    updatedForm[name].value = value
    updatedForm[name].touched = true
    const validatorObject = checkValidator(value, updatedForm[name].validator)
    updatedForm[name].error = {
      status: validatorObject.status,
      message: validatorObject.message
    }
    let formStatus = true
    for (const name in updatedForm) {
      if (updatedForm[name].validator.required) {
        formStatus = !updatedForm[name].error.status && formStatus
      }
    }
    setFormState((formState) => {
      return (
        {
          ...formState,
          formElements: updatedForm,
          formValid: formStatus
        }
      )
    })
  }

  const onFormSubmit = (e) => {
    e.preventDefault()
    const formData = {}

    for (const name in formState.formElements) {
      formData[name] = formState.formElements[name].value
    }

    submit(formData)
  }

  const checkValidator = (value, rule) => {
    let valid = true
    let message = ''

    if (value.trim().length === 0 && rule.required) {
      valid = false
      message = 'Required.'
    }
    // if (value.length < rule.minLength && valid) {
    //     valid = false;
    //     message = `Less than ${rule.minLength} characters.`;
    // }
    // if (value.length > rule.maxLength && valid) {
    //     valid = false;
    //     message = `Greater than ${rule.maxLength} characters.`;
    // }
    // if (rule.pattern === 'password' && valid) {
    //     if (!/(?=.*[a-z])/.test(value)) {
    //         valid = false;
    //         message += 'Password must contain at least 1 lower-case Latin letter.';
    //     }
    //     if (!/(?=.*[A-Z])/.test(value)) {
    //         valid = false;
    //         message += ',Password must contain at least 1 upper-case Latin letter.';
    //     }
    //     if (!/(?=.*[0-9])/.test(value)) {
    //         valid = false;
    //         message += ',Password must contain at least 1 digit.';
    //     }
    // }

    return { status: !valid, message: message }
  }

  const getInputClass = (name) => {
    const elementErrorStatus = formState.formElements[name].error.status

    return elementErrorStatus && formState.formElements[name].touched
      ? 'form-control is-invalid'
      : 'form-control is-valid'
  }

  const getErrorMessage = (name) => formState.formElements[name].error.message

  const clearForm = () => {
    const updatedForm = { ...formState.formElements }

    let formStatus

    for (const name in updatedForm) {
      updatedForm[name].value = ''
      updatedForm[name].touched = true

      const validatorObject = checkValidator('', updatedForm[name].validator)
      updatedForm[name].error = {
        status: validatorObject.status,
        message: validatorObject.message
      }

      formStatus = true

      if (updatedForm[name].validator.required) {
        formStatus = !updatedForm[name].error.status && formStatus
      }
    }

    setFormState((formState) => {
      return (
        {
          ...formState,
          formElements: updatedForm,
          formValid: formStatus
        }
      )
    })
  }

  const submit = (formData) => {
    // dispatch(action.login(formData));

    // I write action here, instead of action.js because I need to know the post response. As useSelector seems don't update immediately.
    dispatch(async (dispatch) => {
      try {
        const res = await fetch(BACKEND_SOCKET + '/api/users/login', {
          method: 'post',
          headers: { 'content-type': 'application/json' },
          body: JSON.stringify(formData)
        })
        const result = await res.text()

        if (res.status === 200) {
          localStorageService.setToken(JSON.parse(result).token)
          const userObj = localStorageService.getUserFromToken()

          dispatch({
            type: 'LOGIN',
            payload: userObj
          })

          if (userObj.role === 'rasPi') {
            clearForm()
          } else if (userObj.role === 'admin') {
            history.push('/admin')
          } else {
            history.push('/')
          }
        } else {
          dispatch({ // show error notification
            type: 'LOGIN_ERROR',
            payload: JSON.parse(result).message
          })

          clearForm()
        }
      } catch (err) {
        console.log(`error: ${err}`)
      }
    })
  }

  const getWarning = () => {
    if (loginErrorSelector.status) {
      return (<p className='text-danger'>{loginErrorSelector.message}</p>)
    }
    if (userSelector) {
      if (userSelector?.role === 'rasPi') {
        return (<p className='text-warning'>Invalid user role.</p>)
      }
    }
    if (props.onlyAdmin) {
      // return( <p className="text-primary">Please login as administrator.</p>)
      return (<p className='text-primary'>Please login.</p>)
    }
    return (<p className='text-primary'>Please login.</p>)
  }

  return (
    <Container fluid>
      <Row>
        <Col sm='3' className='mt-5' />
        <Col sm='6' className='mt-5'>
          <Card>
            <Card.Body className='ml-3 mr-3 mt-1 mb-1'>
              {getWarning()}
              <Form onSubmit={onFormSubmit}>
                <Form.Group controlId='username'>
                  <Form.Label>Username</Form.Label>
                  <Form.Control
                    type='text'
                    className={getInputClass('username')}
                    name='username'
                    value={formState.formElements.username.value}
                    onChange={onFormChange}
                  />
                  <div className='invalid-feedback'>
                    {getErrorMessage('username')}
                  </div>
                </Form.Group>
                <Form.Group controlId='password'>
                  <Form.Label>Password</Form.Label>
                  <input
                    type='password'
                    className={getInputClass('password')}
                    name='password'
                    value={formState.formElements.password.value}
                    onChange={onFormChange}
                  />
                  <div className='invalid-feedback'>
                    {getErrorMessage('password').split(',').map((item, index) => {
                      return <div key={index}>{item}</div>
                    })}
                  </div>
                </Form.Group>
                <div className='text-center'>
                  <Button
                    className='ml-3'
                    type='submit'
                    variant='primary'
                  >
                    Login
                  </Button>
                </div>
              </Form>
            </Card.Body>
          </Card>
        </Col>
        <Col sm='3' className='mt-5' />
      </Row>
    </Container>
  )
}
export default Login
