import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import Badge from 'react-bootstrap/Badge'
import action from '../../../redux/action'

const CustomTags = (props) => {
  const dispatch = useDispatch()

  const [loading, setLoading] = useState(
    {
      tag1: false,
      tag2: false,
      tag3: false
    }
  )

  const onTagClick = (key) => {
    setLoading({ ...loading, [key]: true })

    dispatch(action.setKey(key, props._id, !props.customTag[key]))
      .then(() => setLoading({ ...loading, [key]: false }))
  }

  return (
    <div className='h5 d-flex justify-content-start align-items-center flex-wrap'>
      <Badge
        className='mr-1 clickable' id='tag1'
        pill
        variant={props.customTag.tag1 ? 'primary' : 'secondary'}
        onClick={() => onTagClick('tag1')}
      >
        {
          loading.tag1 ? <div className='lds-ellipsis-small'>
            <div/>
            <div/>
          </div> : '1'
        }
      </Badge>
      <Badge
        className='mr-1 clickable' id='tag2'
        pill
        variant={props.customTag.tag2 ? 'warning' : 'secondary'}
        onClick={() => onTagClick('tag2')}
      >
        {
          loading.tag2 ? <div className='lds-ellipsis-small'>
            <div/>
            <div/>
          </div> : '2'
        }
      </Badge>

      <Badge
        className='mr-1 clickable' id='tag3'
        pill
        variant={props.customTag.tag3 ? 'dark' : 'secondary'}
        onClick={() => onTagClick('tag3')}
      >
        {
          loading.tag3 ? <div className='lds-ellipsis-small'>
            <div/>
            <div/>
          </div> : '3'
        }
      </Badge>
    </div>
  )
}

export default CustomTags
