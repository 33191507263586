import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import Container from 'react-bootstrap/Container'
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import StatusTable from '../bars/StatusBar/StatusTable'
import FilterBar from '../bars/FilterBar'
import History from './Feed/History'
import FeedLatest from './Feed/FeedLatest'
import Login from './Login'

const Home = () => {
  const [key, setKey] = useState('currentStatus')

  const userSelector = useSelector((state) => state.user)

  return (

    <Container fluid>
      {
        (userSelector?.role === 'admin' || userSelector?.role === 'user')
          ? <div>
            <Tabs
              id='feed-tab'
              activeKey={key}
              onSelect={(k) => setKey(k)}
              mountOnEnter={true}
              unmountOnExit={true}
            >
              <Tab eventKey='currentStatus' title='Current Status'>
                <div>
                  <StatusTable/>
                  <FeedLatest isActive={key === 'currentStatus'}/>
                </div>
              </Tab>
              <Tab eventKey='history' title='History'>
                <div>
                  <FilterBar/>
                  <History isActive={key === 'history'}/>
                </div>
              </Tab>
            </Tabs>
          </div>
          : <Login/>
      }
    </Container>
  )
}

export default Home
