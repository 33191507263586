import React from 'react'
import PropTypes from 'prop-types'
import Badge from 'react-bootstrap/Badge'
import Table from 'react-bootstrap/Table'

const RasPiStatus = ({ tx, rx }) => {
  return (
    <Table size='sm' borderless>
      <tbody>
        <tr>
          {/* tx */}
          <td>
            <Badge className={`d-inline ${tx ? 'status-badge-green' : 'status-badge-grey'}`}>Up</Badge>
            <Badge className={`d-inline ${tx ? 'status-badge-grey' : 'status-badge-red'}`}>Down</Badge>
          </td>
        </tr>
        <tr>
          {/* rx */}
          <td>
            <Badge className={`d-inline ${rx ? 'status-badge-green' : 'status-badge-grey'}`}>Up</Badge>
            <Badge className={`d-inline ${rx ? 'status-badge-grey' : 'status-badge-red'}`}>Down</Badge>
          </td>
        </tr>
      </tbody>
    </Table>
  )
}

RasPiStatus.defaultProps = {
  tx: false,
  rx: false
}

RasPiStatus.propTypes = {
  tx: PropTypes.bool,
  rx: PropTypes.bool
}

export default RasPiStatus
