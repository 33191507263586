import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import action from '../redux/action'

const MyNavbar = () => {
  // const showFiltersSelector = useSelector((state) => state.showFilters);
  const userSelector = useSelector((state) => state.user)

  const dispatch = useDispatch()

  const history = useHistory()

  const logoutUser = () => {

    dispatch(action.logout())
      .then(() => {
          history.push('/login')
          // history.go(0);
        }
      )
  }

  const getBg = () => {
    if (userSelector?.role === 'admin') {
      return 'primary'
    } else {
      return 'success'
    }
  }

  return (
    <Navbar bg={getBg()} variant='dark' sticky='top'>
      <Navbar.Brand className='d-none d-sm-block'>Forest Eyes</Navbar.Brand>
      <Nav className='h5 my-auto mr-auto'>
        <Nav.Link as={Link} to='/'>Home</Nav.Link>
        <Nav.Link as={Link} to='/stats'>Stats</Nav.Link>
        {/* <Button
                    className="ml-2"
                    variant="outline-light"
                    disabled={!userSelector}
                    onClick={() => dispatch(action.setShowFilters(!showFiltersSelector))}
                >
                    {showFiltersSelector ? "Hide filters" : "Show filters"}
                </Button> */}
      </Nav>
      <Nav className='ml-auto'>
        {
          (userSelector?.role === 'admin') &&
          <Nav.Link
            className='mr-4'
            as={Link} to='/admin'
          >
            Admin
          </Nav.Link>
        }
        <Nav.Link
          disabled={!userSelector}
          onClick={() => logoutUser()}
        >
          Logout
        </Nav.Link>
      </Nav>
    </Navbar>
  )
}

export default MyNavbar
