import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Card from 'react-bootstrap/Card'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Tags from './Tags'
// import Ratings from './Ratings';
import CustomTags from './CustomTags'
import action from '../../../redux/action'

const Poster = (props) => {
  const ALERT_HOURS = process.env.REACT_APP_ALERT_HOURS || 24

  const queryIdSelector = useSelector((state) => {
    return state.query.data.find((doc) => doc._id === props._id)
  })
  // // const [rating, setRating] = useState(props.rating);
  const tag = {
    isHuman: queryIdSelector.isHuman,
    isWeapon: queryIdSelector.isWeapon,
    isWildlife: queryIdSelector.isWildlife,
    isDomestic: queryIdSelector.isDomestic
  }
  const customTag = {
    tag1: queryIdSelector.tag1,
    tag2: queryIdSelector.tag2,
    tag3: queryIdSelector.tag3
  }

  const dispatch = useDispatch()

  // const handleRatingClick = (e) => {

  // //     //use currentTarget.id because of https://stackoverflow.com/questions/10086427/what-is-the-exact-difference-between-currenttarget-property-and-target-property
  //     switch (e.currentTarget.id) {
  //         case "rating-1":
  //             if (rating === 1) {
  //                 setRating(0);
  //             } else {
  //                 setRating(1);
  //             }
  //             break;
  //         case "rating-2":
  //             if (rating === 2) {
  //                 setRating(0);
  //             } else {
  //                 setRating(2);
  //             }
  //             break;
  //         case "rating-3":
  //             if (rating === 3) {
  //                 setRating(0);
  //             } else {
  //                 setRating(3);
  //             }
  //             break;
  //         default:
  //     }

  // }

  const getBG = (createdDate) => {
    // hours = (now - createDate) / (60 * 60 * 1000)
    const hours = (new Date() - createdDate) / 36e5

    if (hours < ALERT_HOURS) {
      return 'info'
    }
    return ''
  }

  return (
    <>
      <Card
        className='mt-2 mx-auto'
        style={{ width: 'auto' }}
        bg={getBG(new Date(queryIdSelector.createdAt))}
      >
        <Card.Header className='d-flex justify-content-between'>
          <FontAwesomeIcon
            className='clickable'
            icon={[(queryIdSelector.isLiked ? 'fas' : 'far'), 'heart']}
            size='lg' color='tomato'
            onClick={() => dispatch(action.setKey('isLiked', props._id, !queryIdSelector.isLiked))}
          />
          <FontAwesomeIcon
            className='clickable'
            icon={[(queryIdSelector.isHidden ? 'fas' : 'far'), 'trash-alt']}
            size='lg' color='silver'
            onClick={() => dispatch(action.setKey('isHidden', props._id, !queryIdSelector.isHidden))}
          />
        </Card.Header>
        <Card.Img variant='top' src={queryIdSelector.img} style={{ height: 'auto' }} />
        <Card.Body>
          <Card.Title>RasPI: {queryIdSelector.rasPiId} - Camera: {queryIdSelector.camId}</Card.Title>
          <Card.Text>File name: {queryIdSelector.fileName}</Card.Text>
          <Card.Text>Sent at: {new Date(queryIdSelector.sentAt).toLocaleString('en-GB')}</Card.Text>
          {/* <Card.Text>Created at: {new Date(queryIdSelector.createdAt).toLocaleString('en-GB')}</Card.Text>
                    <Card.Text>Updated at: {new Date(queryIdSelector.updatedAt).toLocaleString('en-GB')}</Card.Text> */}
        </Card.Body>
        <Card.Footer className='d-flex justify-content-between'>

          <Tags
            _id={props._id}
            tag={tag}
          />

          {/* <Ratings rating={rating} handleRatingClick={(e) => handleRatingClick(e)} /> */}
          <CustomTags
            _id={props._id}
            customTag={customTag}
          />

        </Card.Footer>
      </Card>
    </>
  )
}
export default Poster
