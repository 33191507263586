import React from 'react'
import Pagination from 'react-bootstrap/Pagination'

const LogPagination = (props) => {
  return (
    <div className='LogPagination mt-3'>
      <Pagination size='sm'>
        <Pagination.First
          disabled={props.pagination.pageNumber === 1}
          onClick={() => props.setPagination({ ...props.pagination, pageNumber: 1 })}
        />
        <Pagination.Prev
          disabled={props.pagination.pageNumber === 1}
          onClick={() => props.setPagination({ ...props.pagination, pageNumber: props.pagination.pageNumber - 1 })}
        />

        {props.pagination.paginationItems}

        <Pagination.Next
          disabled={props.pagination.pageNumber === props.pagination.pageCount}
          onClick={() => props.setPagination({ ...props.pagination, pageNumber: props.pagination.pageNumber + 1 })}
        />
        <Pagination.Last
          disabled={props.pagination.pageNumber === props.pagination.pageCount}
          onClick={() => props.setPagination({ ...props.pagination, pageNumber: props.pagination.pageCount })}
        />
      </Pagination>
    </div>
  )
}

export default LogPagination
