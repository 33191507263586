import { useSelector } from 'react-redux'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import AdminPanel from './Admin/AdminPanel'
import Login from './Login'

const AdminMain = (props) => {
  const userSelector = useSelector((state) => state.user)

  return (
    <Container fluid>
      <Row>
        {
                    userSelector?.role === 'admin'
                      ? <AdminPanel />
                      : <Login onlyAdmin />
                }
      </Row>
    </Container>
  )
}

export default AdminMain
