import React from 'react'
import SessionStatus from './StatusBar/SessionStatus'
// import QueryStatus from './StatusBar/QueryStatus'

const StatusBar = () => {
  return (
    <>
      <SessionStatus />
      {/*<QueryStatus />*/}
    </>
  )
}

export default StatusBar
