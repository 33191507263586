import * as d3 from 'd3'

const MARGIN = { TOP: 10, BOTTOM: 75, LEFT: 70, RIGHT: 10 }
const WIDTH = 800 - MARGIN.LEFT - MARGIN.RIGHT
const HEIGHT = 505 - MARGIN.TOP - MARGIN.BOTTOM
// const MULTIPLIER = 0.95;
// const BAR_PADDING = 0;
const MAX_Y_TICKS = 20
const TRANSITION_MSECS = 500
const DELAY_MSECS = 500
const LABEL_PADDING_TOP = 0

export default class Histogram {
  constructor (element, data, interval) {
    const vis = this
    vis.element = element

    // console.log("constructor")
    // console.log(data);
    // console.log(interval);
    // console.log("constructor ends")

    // append the svg object to the body of the page
    // append a 'group' element to 'svg'
    // moves the 'group' element to the top left margin

    vis.svg = d3.select(vis.element)
      .append('svg')
      .attr('width', WIDTH + MARGIN.LEFT + MARGIN.RIGHT)
      .attr('height', HEIGHT + MARGIN.TOP + MARGIN.BOTTOM)
      .append('g')
      .attr('transform', `translate(${MARGIN.LEFT},${MARGIN.TOP})`)

    // Scale the range of x
    vis.xScale = d3.scaleTime()
      .rangeRound([0, WIDTH])

    // Scale the range of y
    vis.yScale = d3.scaleLinear()
      .range([HEIGHT, 0])

    // add xAxis
    vis.xAxisGroup = vis.svg.append('g')
      .attr('transform', `translate(0, ${HEIGHT})`)

    // add yAxis
    vis.yAxisGroup = vis.svg.append('g')

    // add the x Label
    vis.svg.append('text')
      .attr('x', WIDTH / 2)
      .attr('y', HEIGHT + 60)
      .attr('font-size', 20)
      .attr('text-anchor', 'middle')
      .classed('xLabel', true)
    // .text("Time")

    // add the y Label
    vis.svg.append('text')
      .attr('x', -(HEIGHT / 2))
      .attr('y', -30)
      .attr('transform', 'rotate(-90)')
      .attr('font-size', 20)
      .attr('text-anchor', 'middle')
      .text('Frequency')

    vis.update(data, interval)
  }

  update (data, interval) {
    const vis = this
    vis.data = data
    vis.interval = interval

    // update xLabel
    d3.select('.xLabel')
      .text(`Time (${vis.interval})`)

    // find max and min x
    const minX = d3.min(vis.data, (d) => d.createdAt)
    const maxX = d3.max(vis.data, (d) => d.createdAt)

    // console.log(`min ${minX} max ${maxX}`)

    // defined domain from data.min, data.max, floor and ceil date to make it full interval slot
    vis.xScale.domain([
      vis.floorDate(minX, vis.interval),
      vis.ceilDate(maxX, vis.interval)
    ])

    // calculate histogram thresholds
    const thresholds = vis.xScale.ticks(vis.getTick(vis.interval))
    // console.log("thresholds:")
    // console.log(thresholds);
    // console.log("thresholds ends.")

    // set the parameters for the histogram
    const histogram = d3.bin()
      .value((d) => d.createdAt)
      .domain(vis.xScale.domain())
      .thresholds(thresholds)

    // group the data for the bars
    const bins = histogram(vis.data)

    // calculate bar width by WIDTH and bins, doesn't work with month interval, because not all months have the same length.
    // must use x1 - x0 to calculate bar width
    // const barWidth = WIDTH / (bins.length - 1);

    // console.log("bins (as an array), bins.length is the size of the array")
    // console.log(bins)
    // console.log("bins end")

    // store max Y for Y domain and  Y ticks
    const maxY = +d3.max(bins, (d) => d.length)

    // Scale the range of the data in the y domain
    vis.yScale = d3.scaleLinear()
      .domain([0, maxY])
      .range([HEIGHT, 0])

    // custom x scale
    const xAxis = d3.axisBottom()
      .scale(vis.xScale)
    // .ticks(vis.getTick(vis.interval).every(1))
      .ticks(vis.getTick(vis.interval))
    // .tickSize(-HEIGHT)
      .tickFormat(vis.multiFormatDate)

    // custom y scale
    const yAxis = d3.axisLeft()
      .scale(vis.yScale)
      .ticks(Math.min(maxY, MAX_Y_TICKS))
      .tickSize(-WIDTH)
      .tickFormat(d3.format('d'))

    // render xAxis
    vis.xAxisGroup.transition().duration(TRANSITION_MSECS)
      .call(xAxis)
      .selectAll('text')
      .style('text-anchor', 'end')
      .attr('dx', '-.8em')
      .attr('dy', '.15em')
      .attr('transform', 'rotate(-65)')

    // render yAxis
    vis.yAxisGroup.transition().duration(TRANSITION_MSECS)
      .call(yAxis)

    // DATA JOIN
    // append the bar rectangles to the svg element
    const entries = vis.svg.selectAll('g.entry')
      .data(bins)

    // EXIT
    // remove exceed entries (bar rectangles + label appended to the svg 'g' element)
    entries.each(function () { // the callback actually have 2 variables, datum and iterate
      d3.select(this).select('text.label')
        .exit()
        .transition().duration(TRANSITION_MSECS)
        .attr('opacity', '0')
        .text('')
        .remove()
      d3.select(this).select('rect.bin')
        .exit()
        .transition().duration(TRANSITION_MSECS)
        .attr('height', 0)
        .attr('y', HEIGHT)
        .remove()
    })
    entries.exit().remove()

    // UPDATE
    // update the entries that haven't been removed (bar rectangles + label appended to the svg 'g' element)
    entries.each(function () { // the callback actually have 2 variables, datum and iterate
      d3.select(this).select('rect.bin')
        .transition().duration(TRANSITION_MSECS)
        .attr('transform', (d) => `translate(${vis.xScale(d.x0)},${vis.yScale(d.length)})`)
        .attr('width', (d) => vis.xScale(d.x1) - vis.xScale(d.x0))
        .attr('height', (d) => HEIGHT - vis.yScale(d.length))
      d3.select(this).select('text.label')
        .attr('x', (d) => (vis.xScale(d.x0) + (vis.xScale(d.x1) - vis.xScale(d.x0)) / 2))
        .attr('y', (d) => vis.yScale(d.length) + LABEL_PADDING_TOP)
        .attr('opacity', '0')
        .text('')
        .transition().duration(TRANSITION_MSECS).delay(DELAY_MSECS)
        .attr('opacity', '1')
        .text((d) => (+d.length) > 0 ? d.length : '')
    })

    // ENTER
    // append the entries (bar rectangles + label appended to the svg 'g' element) at the first time
    // or then there are new entries
    entries.enter().append('g')
      .attr('class', 'entry')
      .each(function () { // the callback actually have 2 variables, datum and iterate
        d3.select(this).append('rect')
          .attr('class', 'bin')
          .attr('fill', '#00676E') // Thai tone 2.0 เขียวขาบ
          .attr('stroke', 'white')
          .attr('stroke-width', 0.5)
          .attr('transform', (d) => `translate(${vis.xScale(d.x0)},${vis.yScale(d.length)})`)
          .transition().duration(TRANSITION_MSECS)
          .attr('width', (d) => vis.xScale(d.x1) - vis.xScale(d.x0))
          .attr('height', (d) => HEIGHT - vis.yScale(d.length))
        d3.select(this).append('text')
          .attr('class', 'label')
          .attr('x', (d) => (vis.xScale(d.x0) + (vis.xScale(d.x1) - vis.xScale(d.x0)) / 2))
          .attr('y', (d) => vis.yScale(d.length) + LABEL_PADDING_TOP)
          .attr('opacity', '0')
          .transition().duration(TRANSITION_MSECS).delay(DELAY_MSECS)
          .attr('opacity', '1')
          .text((d) => (+d.length) > 0 ? d.length : '')
      })
  }

  getTick (interval) {
    const vis = this
    vis.interval = interval

    switch (vis.interval) {
      case 'hourly':
        return d3.timeHour
      case 'daily':
        return d3.timeDay
      case 'weekly':
        return d3.timeWeek
      case 'monthly':
        return d3.timeMonth
      case 'yearly':
        return d3.timeYear
      default:
        return d3.timeDay
    }
  }

  multiFormatDate (date) {
    const vis = date
    vis.date = date

    const formatMillisecond = d3.timeFormat('.%L')
    const formatSecond = d3.timeFormat(':%S')
    const formatMinute = d3.timeFormat('%H:%M')
    const formatHour = d3.timeFormat('%H:00')
    const formatDay = d3.timeFormat('%e %b')
    const formatWeek = d3.timeFormat('%e %b')
    const formatMonth = d3.timeFormat('%b')
    const formatYear = d3.timeFormat('%Y')

    return (d3.timeSecond(vis.date) < vis.date ? formatMillisecond
      : d3.timeMinute(vis.date) < vis.date ? formatSecond
        : d3.timeHour(vis.date) < vis.date ? formatMinute
          : d3.timeDay(vis.date) < vis.date ? formatHour
            : d3.timeMonth(vis.date) < vis.date ? (d3.timeWeek(vis.date) < vis.date ? formatDay : formatWeek)
              : d3.timeYear(vis.date) < vis.date ? formatMonth
                : formatYear)(vis.date)
  }

  // return as integer not Date, but works.
  floorDate (date, interval) {
    const vis = this
    vis.date = date
    vis.interval = interval

    const result = new Date(vis.date)

    switch (vis.interval) {
      case 'hourly':
        return result.setMinutes(0, 0, 0)
      case 'daily':
        return result.setHours(0, 0, 0, 0)
      case 'weekly':
        return vis.getSunday(result)
      case 'monthly':
        result.setMonth(result.getMonth(), 1)
        return result.setHours(0, 0, 0, 0)
      case 'yearly':
        result.setMonth(0, 1)
        return result.setHours(0, 0, 0, 0)
      default:
        return result.setHours(0, 0, 0, 0)
    }
  }

  // return as integer not Date, but works.
  ceilDate (date, interval) {
    const vis = this
    vis.date = date
    vis.interval = interval

    const result = new Date(vis.date)

    switch (vis.interval) {
      case 'hourly':
        return result.setHours(result.getHours() + 1, 0, 0, 0)
      case 'daily':
        result.setDate(result.getDate() + 1)
        return result.setHours(0, 0, 0, 0)
      case 'weekly':
        result.setDate(result.getDate() + 7)
        return vis.getSunday(result)
      case 'monthly':
        result.setMonth(result.getMonth() + 1, 1)
        return result.setHours(0, 0, 0, 0)
      case 'yearly':
        result.setFullYear(result.getFullYear() + 1)
        result.setMonth(0, 1)
        return result.setHours(0, 0, 0, 0)
      default:
        return result.setHours(1, 0, 0, 0)
    }
  }

  getSunday (date) {
    const vis = this
    vis.date = date

    const day = vis.date.getDay()
    const diff = vis.date.getDate() - day
    // const diff = vis.date.getDate() - day + (day == 0 ? -6:1); // If the first day of week is Monday

    return new Date(vis.date.setDate(diff)).setHours(0, 0, 0, 0)
  }
}
