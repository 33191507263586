import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import Container from 'react-bootstrap/Container'
import Pagination from 'react-bootstrap/Pagination'
import localStorageService from '../../../services/localStorageService'
import action from '../../../redux/action'
import LogFilters from '../LogFilters'
import LogPagination from './LogPagination'
import LogTable from './LogTable'
import CSVButton from './CSVButton'

const Logs = (props) => {
  const BACKEND_SOCKET = process.env.REACT_APP_BACKEND_SOCKET || 'http://localhost:3001'
  // const RELOAD_LOGS_MSEC = Number(process.env.REACT_APP_RELOAD_LOGS_MSEC) || 6e4;
  const DAY_RANGE_LOGS = Number(process.env.REACT_APP_DAY_RANGE_LOGS) || 7
  const LOGS_PAGE_SIZE = Number(process.env.REACT_APP_LOGS_PAGE_SIZE) || 100
  const MAX_PAGINATION_ITEMS = 10

  const [logs, setLogs] = useState([])
  const [filters, setFilters] = useState(() => {
    const startDate = new Date()
    const now = new Date()

    return {
      rasPiType: 'Tx+Rx',
      rasPiId: 'All',
      startDate: new Date(startDate.setDate(now.getDate() - DAY_RANGE_LOGS)),
      endDate: now
    }
  })
  const [pagination, setPagination] = useState({
    pageNumber: 1,
    pageSize: LOGS_PAGE_SIZE,
    pageCount: 1,
    paginationItems: []
  })
  const [filterString, setFilterString] = useState('')

  const dispatch = useDispatch()

  useEffect(() => {
    if (!props.isActive) {
      return
    }

    // let timer;
    const fetcher = async () => {
      setFilterString(`rasPiType=${filters.rasPiType}&rasPiId=${filters.rasPiId}&startDate=${filters.startDate}&endDate=${filters.endDate}`)
      const paginationString = `pageSize=${pagination.pageSize}&pageNumber=${pagination.pageNumber}`

      try {
        const res = await fetch(`${BACKEND_SOCKET}/api/logs/log_count?${filterString}`, {
          method: 'get',
          headers: {
            'content-type': 'application/json;charset=UTF-8',
            'Authorization': 'Bearer ' + localStorageService.getToken()
          }
        })

        if (res.status === 200) {
          const data = await res.json()
          const divided = Math.floor(data.count / pagination.pageSize)
          const pageCount = (data.count % pagination.pageSize === 0) ? divided : divided + 1

          const lowerBound = Math.max(pagination.pageNumber - (MAX_PAGINATION_ITEMS / 2 - 1), 1)
          const upperBound = Math.min(Math.max(pagination.pageNumber + (MAX_PAGINATION_ITEMS / 2 - 1), MAX_PAGINATION_ITEMS), pageCount)

          const items = []

          for (let i = lowerBound; i <= upperBound; i++) {
            items.push(
              <Pagination.Item
                key={i}
                active={(pagination.pageNumber === i)}
                onClick={() => setPagination({ ...pagination, pageNumber: i })}
              >
                {i}
              </Pagination.Item>
            )
          }

          setPagination({
            ...pagination,
            pageCount: pageCount,
            paginationItems: items
          })
        } else if (res.status === 401) {
          // console.log("Session timeout.");
          dispatch({
            type: 'AUTHORIZATION_ERROR'
          })
          dispatch(action.logout())

        } else {
          console.log('Error on something else.')
        }
      } catch (err) {
        console.log(`error: ${err}`)
      }

      try {
        const res = await fetch(`${BACKEND_SOCKET}/api/logs/log_data_paged?${filterString}&${paginationString}`, {
          method: 'get',
          headers: {
            'content-type': 'application/json;charset=UTF-8',
            'Authorization': 'Bearer ' + localStorageService.getToken()
          }
        })
        if (res.status === 200) {
          const data = await res.json()

          data.forEach((item) => {
            // both
            item.createdAt = new Date(item.createdAt)
            item.loggedAt = new Date(item.loggedAt)

            // only rx
            item.lastReceivedDate = (item.lastReceivedDate) ? new Date(item.lastReceivedDate) : undefined

            // only tx
            item.lastSentDate = (item.lastSentDate) ? new Date(item.lastSentDate) : undefined

            item.lastCamActiveDate = (item.lastCamActiveDate) ? new Date(item.lastCamActiveDate) : undefined
          })

          // console.log(data)

          setLogs(data)
        } else if (res.status === 401) {
          console.log('Session timeout.')
          dispatch({
            type: 'AUTHORIZATION_ERROR'
          })
          dispatch(action.logout())

        } else {
          console.log('Error on something else.')
        }
      } catch (err) {

        console.log(`error: ${err}`)
      }
    }

    fetcher().then()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, pagination.pageNumber, props.isActive])

  useEffect(() => {
    dispatch(action.queryUserDb())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onFilterTypeClick = (x) => {
    setFilters({ ...filters, rasPiType: x })
  }

  const onFilterRasPiIdClick = (x) => {
    setFilters({ ...filters, rasPiId: x })
  }

  const onFilterStartDateClick = (date) => {
    setFilters({ ...filters, startDate: new Date(date.setSeconds(0, 0)) })
  }

  const onFilterEndDateClick = (date) => {
    setFilters({ ...filters, endDate: new Date(date.setSeconds(0, 0)) })
  }

  const renderLogs = () => {
    if (logs.length === 0) {
      return 'No data to show. Please try editing the filters.'
    }

    return (
      <>
        <LogPagination
          pagination={pagination}
          setPagination={(x) => setPagination(x)}
        />
        <CSVButton
          filterString={filterString}
        />
        <LogTable
          logs={logs}
          filters={filters}
        />
      </>
    )
  }

  return (
    <div className='Logs'>
      <Container fluid>
        <LogFilters
          filters={filters}
          onFilterTypeClick={(x) => onFilterTypeClick(x)}
          onFilterRasPiIdClick={(x) => onFilterRasPiIdClick(x)}
          onFilterStartDateClick={(date) => onFilterStartDateClick(date)}
          onFilterEndDateClick={(date) => onFilterEndDateClick(date)}
        />

        {renderLogs()}
      </Container>
    </div>
  )
}

export default Logs
