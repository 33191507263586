import React from 'react'
import Dropdown from 'react-bootstrap/Dropdown'

const IntervalDropdown = (props) => {
  return (
    <Dropdown>
      <Dropdown.Toggle variant='outline-primary' size='sm' id='dropdown-basic'>
        Please select Interval
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item onSelect={(x) => props.setInterval('hourly')}>Hourly</Dropdown.Item>
        <Dropdown.Item onSelect={(x) => props.setInterval('daily')}>Daily</Dropdown.Item>
        <Dropdown.Item onSelect={(x) => props.setInterval('weekly')}>Weekly</Dropdown.Item>
        <Dropdown.Item onSelect={(x) => props.setInterval('monthly')}>Monthly</Dropdown.Item>
        <Dropdown.Item onSelect={(x) => props.setInterval('yearly')}>Yearly</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default IntervalDropdown
