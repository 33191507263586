import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import Container from 'react-bootstrap/Container'
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Image from 'react-bootstrap/Image'
import Compress from 'compress.js'
import localStorageService from '../../services/localStorageService'

const BACKEND_SOCKET = process.env.REACT_APP_BACKEND_SOCKET || 'http://localhost:3001'
const MAX_WIDTH = 480

const RasPiForm = (props) => {
  const [rasPiData, setRasPiData] = useState({ undefined: [] })
  const inputRef = useRef(null)
  const [willEdit, setWillEdit] = useState(false)
  const [textLabel, setTextLabel] = useState('Label:')
  const [textTx, setTexTx] = useState('Click at the image to upload a new image.')
  const [textRx, setTexRx] = useState('Click at the image to upload a new image.')
  const [imageTx, setImageTx] = useState({ preview: '' })
  const [imageRx, setImageRx] = useState({ preview: '' })

  const dispatch = useDispatch()

  useEffect(() => {
    const getRasPiData = async (username) => {
      // if (props.role !== 'rasPi') {
      //   return
      // }

      const id = username.slice(4, -2)

      // console.log(id)

      try {
        const res = await fetch(`${BACKEND_SOCKET}/api/users/rasPi_data?id=${id}`, {
          method: 'get',
          headers: {
            'content-type': 'application/json;charset=UTF-8',
            'Authorization': 'Bearer ' + localStorageService.getToken()
          }
        })
        if (res.status === 200) {
          const data = await res.json()

          // console.log(data)

          setRasPiData(data[id][0])

        } else if (res.status === 401) {

          dispatch({
            type: 'AUTHORIZATION_ERROR'
          })
        } else {
          console.log('else')
        }
      } catch (err) {
        console.log(`error: ${err}`)
      }
    }

    getRasPiData(props.username).then()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  // }, [props.renderTrigger])

  const editRasPiLabel = async () => {
    const body = {
      username: `undp${rasPiData.rasPiId}rx`,
      label: inputRef.current.value
    }
    try {
      const res = await fetch(`${BACKEND_SOCKET}/api/users/rasPi_label`, {
        method: 'post',
        headers: {
          'content-type': 'application/json;charset=UTF-8',
          'Authorization': 'Bearer ' + localStorageService.getToken()
        },
        body: JSON.stringify(body)
      })
      if (res.status === 200) {
        // props.setRenderTrigger(!props.renderTrigger)
        setRasPiData({ ...rasPiData, label: inputRef.current.value })
        setTextLabel('Label edited.')
        setWillEdit(false)
      }
    } catch (err) {
      console.log(err)
    }
  }

  const resizeImage = async (file) => {

    const compress = new Compress()

    const resizedImage = await compress.compress([file], {
      size: 1, //MB
      quality: 0.95, //Max 1
      maxWidth: MAX_WIDTH, //default 1920
      resize: true
    })

    const { data: base64Str, ext: imgExt } = resizedImage[0]

    return Compress.convertBase64ToFile(base64Str, imgExt)
  }

  const handleUpload = async (image, tag) => {

    if (tag === undefined) {
      return
    }

    const formData = new FormData()

    formData.set('file', image, `undp${rasPiData.rasPiId}${tag}.jpg`) //fieldname, file, originalname
    //everything will be in req.file nothing will be in req.body

    // example
    // {
    //   fieldname: 'file',
    //   originalname: 'undp2tx.jpg',
    //   encoding: '7bit',
    //   mimetype: 'image/jpeg',
    //   destination: 'uploads/rasPis',
    //   filename: '36c0725ca25d56aba19d1549582119af',
    //   path: 'uploads\\rasPis\\36c0725ca25d56aba19d1549582119af',
    //   size: 102205
    // }

    try {
      const res = await fetch(`${BACKEND_SOCKET}/api/users/rasPi_image`, {
        method: 'post',
        headers: {
          // 'Content-Type': 'multipart/form-data',  //doesn't need this one because FormData will automatically added it with boundary
          'Authorization': 'Bearer ' + localStorageService.getToken()
        },
        body: formData
      })
      if (res.status === 201) {
        switch (tag){
          case 'tx':
            setTexTx('Image uploaded. Click at the image to another image.')
            break
          case 'rx':
            setTexRx('Image uploaded. Click at the image to another image.')
            break
          default:
            return
        }

      } else {
        console.log(`${res.status}`)
      }
    } catch (err) {
      console.log(err)
    }
  }

  const handleChange = async (e, tag) => {
    if (!e.target.files.length) {
      return
    }

    if (tag === undefined) {
      return
    }

    const resizedImage = await resizeImage(e.target.files[0])

    switch (tag) {
      case 'tx':
        setImageTx({
          preview: URL.createObjectURL(resizedImage)
        })
        break
      case 'rx':
        setImageRx({
          preview: URL.createObjectURL(resizedImage)
        })
        break
      default:
        return
    }

    await handleUpload(resizedImage, tag)
  }

  return (
    <Container className='RasPiForm'>
      <Form>
        <Form.Group>
          <Form.Label className='text-muted'>{textLabel}</Form.Label>
          <Form.Row>
            <Col>
              <Form.Control
                type='text'
                ref={inputRef}
                placeholder={rasPiData.label}
              />
            </Col>
            <Col>
              {
                !willEdit &&
                <Button
                  variant='outline-primary'
                  size='sm'
                  onClick={() => {
                    setWillEdit(true)
                    setTextLabel('Please fill a new label and confirm.')
                  }
                  }
                >
                  Edit
                </Button>
              }
              {
                willEdit &&
                <Button
                  variant='primary'
                  size='sm'
                  onClick={() => editRasPiLabel()}
                >
                  Confirm
                </Button>
              }
            </Col>
          </Form.Row>
        </Form.Group>

        <Form.Group>
          <Form.Label>Tx image</Form.Label>
          <Form.Text className='text-muted'>{textTx}</Form.Text>
          <Form.Label htmlFor="upload-button-tx">
            {
              imageTx.preview ?
                <Image
                  src={imageTx.preview}
                  thumbnail
                  style={{ width: `${MAX_WIDTH}px` }}
                />
                :
                <Image
                  src={rasPiData.imgTx}
                  thumbnail
                  style={{ width: `${MAX_WIDTH}px` }}
                />
            }
          </Form.Label>
          <input
            type="file"
            id="upload-button-tx"
            style={{ display: 'none' }}
            onChange={async (e) => await handleChange(e, 'tx')}
          />
        </Form.Group>

        <Form.Group>
          <Form.Label>Rx image</Form.Label>
          <Form.Text className='text-muted'>{textRx}</Form.Text>
          <Form.Label htmlFor="upload-button-rx">
            {
              imageRx.preview ?
                <Image
                  src={imageRx.preview}
                  thumbnail
                  style={{ width: `${MAX_WIDTH}px` }}
                />
                :
                <Image
                  src={rasPiData.imgRx}
                  thumbnail
                  style={{ width: `${MAX_WIDTH}px` }}
                />
            }
          </Form.Label>
          <input
            type="file"
            id="upload-button-rx"
            style={{ display: 'none' }}
            onChange={async (e) => await handleChange(e, 'rx')}
          />
        </Form.Group>
      </Form>
    </Container>
  )
}

export default RasPiForm
