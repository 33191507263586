import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Table from 'react-bootstrap/Table'
import Badge from 'react-bootstrap/Badge'
import Collapse from 'react-bootstrap/Collapse'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import action from '../../redux/action'

const ControlPanel = () => {
  const showFiltersSelector = useSelector((state) => state.showFilters)
  const filtersSelector = useSelector((state) => state.filters)

  const dispatch = useDispatch()

  return (
    <div className='ControlPanel'>
      <Collapse in={showFiltersSelector} mountOnEnter unmountOnExit>
        <div id="collapse-controlPanel">
          <Table borderless='true' size='sm'>
            <tbody>
            <tr>
              <td width={'20.6%'}>Type (and)</td>
              <td>
                <div
                  className='d-flex justify-content-start align-items-start'
                >
                  <Badge
                    className={`mr-1 clickable ${(!(filtersSelector.isLiked || filtersSelector.isHidden) ? 'tag-none' : '')}`}
                    id='typeNone'
                    variant='secondary'
                    onClick={() => {
                      dispatch(action.setFilter('isLiked', false))
                      dispatch(action.setFilter('isHidden', false))
                    }}
                  >
                    <span className='h6'>Any</span>
                  </Badge>
                  <Badge
                    className={`mr-1 clickable ${(filtersSelector.isLiked ? 'tag-like' : '')}`}
                    id='isLiked'
                    variant='secondary'
                    onClick={() => dispatch(action.setFilter('isLiked', !filtersSelector.isLiked))}
                  >
                    <FontAwesomeIcon
                      className='d-inline '
                      icon={['fas', 'heart']}
                      size='lg' color='tomato'
                    />
                    <span className='h6'> Liked</span>
                  </Badge>
                  <Badge
                    className={`mr-1 clickable ${(filtersSelector.isHidden ? 'tag-hide' : '')}`}
                    id='isHidden'
                    variant='secondary'
                    onClick={() => dispatch(action.setFilter('isHidden', !filtersSelector.isHidden))}
                  >
                    <FontAwesomeIcon
                      className='clickable'
                      icon={['fas', 'trash-alt']}
                      size='lg' color='silver'
                    />
                    <span className='h6'> Hidden</span>
                  </Badge>
                </div>
              </td>
            </tr>
            <tr>
              <td>Tag group (or)</td>
              <td>
                <div className='d-flex justify-content-start flex-wrap'>
                  <Badge
                    className={`mr-1 mb-1 clickable ${(!(filtersSelector.isHuman || filtersSelector.isWeapon || filtersSelector.isWildlife || filtersSelector.isDomestic) ? 'tag-none' : '')}`}
                    id='tagGroupNone'
                    variant='secondary'
                    onClick={() => {
                      dispatch(action.setFilter('isHuman', false))
                      dispatch(action.setFilter('isWeapon', false))
                      dispatch(action.setFilter('isWildlife', false))
                      dispatch(action.setFilter('isDomestic', false))
                    }}
                  >
                    <span className='h6'>Any</span>
                  </Badge>
                  <Badge
                    className={`mr-1 mb-1 clickable ${(filtersSelector.isHuman ? 'tag-human' : '')}`}
                    id='human'
                    variant='secondary'
                    onClick={() => dispatch(action.setFilter('isHuman', !filtersSelector.isHuman))}
                  >
                    <span className='h6'>Human</span>
                  </Badge>
                  <Badge
                    className={`mr-1 mb-1 clickable ${(filtersSelector.isWeapon ? 'tag-weapon' : '')}`}
                    id='weapon'
                    variant='secondary'
                    onClick={() => dispatch(action.setFilter('isWeapon', !filtersSelector.isWeapon))}
                  >
                    <span className='h6'>Weapon</span>
                  </Badge>
                  <Badge
                    className={`mr-1 mb-1 clickable ${(filtersSelector.isWildlife ? 'tag-wildlife' : '')}`}
                    id='wildlife'
                    variant='secondary'
                    onClick={() => dispatch(action.setFilter('isWildlife', !filtersSelector.isWildlife))}
                  >
                    <span className='h6'>Wildlife</span>
                  </Badge>
                  <Badge
                    className={`mr-1 mb-1 clickable ${(filtersSelector.isDomestic ? 'tag-domestic' : '')}`}
                    id='domestic'
                    variant='secondary'
                    onClick={() => dispatch(action.setFilter('isDomestic', !filtersSelector.isDomestic))}
                  >
                    <span className='h6'>Domestic</span>
                  </Badge>
                </div>
              </td>
            </tr>
            <tr>
              <td>Custom group (or)</td>
              <td>
                <div className='d-flex justify-content-start'>
                  <Badge
                    className={`mr-1 clickable ${(!(filtersSelector.tag1 || filtersSelector.tag2 || filtersSelector.tag3) ? 'tag-none' : '')}`}
                    id='tagGroupNone'
                    variant='secondary'
                    onClick={() => {
                      dispatch(action.setFilter('tag1', false))
                      dispatch(action.setFilter('tag2', false))
                      dispatch(action.setFilter('tag3', false))
                    }}
                  >
                    <span className='h6'>Any</span>
                  </Badge>
                  <Badge
                    className='mr-1 clickable' id='tag1'
                    pill
                    variant={filtersSelector.tag1 ? 'primary' : 'secondary'}
                    onClick={() => dispatch(action.setFilter('tag1', !filtersSelector.tag1))}
                  >
                    <span className='h6'>1</span>
                  </Badge>
                  <Badge
                    className='mr-1 clickable' id='tag2'
                    pill
                    variant={filtersSelector.tag2 ? 'warning' : 'secondary'}
                    onClick={() => dispatch(action.setFilter('tag2', !filtersSelector.tag2))}
                  >
                    <span className='h6'>2</span>
                  </Badge>
                  <Badge
                    className='mr-1 clickable' id='tag3'
                    pill
                    variant={filtersSelector.tag3 ? 'info' : 'secondary'}
                    onClick={() => dispatch(action.setFilter('tag3', !filtersSelector.tag3))}
                  >
                    <span className='h6'>3</span>
                  </Badge>

                </div>
              </td>
            </tr>
            </tbody>
          </Table>
        </div>
      </Collapse>
    </div>
  )
}

export default ControlPanel
