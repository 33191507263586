import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Button from 'react-bootstrap/Button'
import Collapse from 'react-bootstrap/Collapse'
import DatePicker, { registerLocale } from 'react-datepicker'
import th from 'date-fns/locale/th'
import gb from 'date-fns/locale/en-GB'
import 'react-datepicker/dist/react-datepicker.css'
import action from '../../redux/action'

const DateButtonBar = () => {

  const dateButtonSelector = useSelector((state) => state.dateButtons)
  const filtersSelector = useSelector((state) => state.filters)

  const dispatch = useDispatch()

  registerLocale('th', th)
  registerLocale('gb', gb)

  const setStartDate = (time) => {
    const now = new Date()
    let date

    switch (time) {
      case '1w':
        date = new Date(now.setDate(now.getDate() - 7))
        dispatch(action.setDateButtons(1))
        break
      case '2w':
        date = new Date(now.setDate(now.getDate() - 14))
        dispatch(action.setDateButtons(2))
        break
      case '3w':
        date = new Date(now.setDate(now.getDate() - 21))
        dispatch(action.setDateButtons(3))
        break
      case '1m':
        date = new Date(now.setDate(now.getDate() - 30))
        dispatch(action.setDateButtons(4))
        break
      default:
        return
    }

    // console.log('setStartDate starts, startDate.')
    // console.log(date)
    dispatch(action.setFilter('startDate', date))
    // console.log('setStartDate ends, startDate.')
  }

  const onCustomButtonClick = () => {
    dispatch(action.setDateButtons(0))
  }

  return (
    <>
      <div className='d-flex justify-content-start'>
        <Button
          className='mr-1'
          variant='outline-primary'
          size='sm'
          active={dateButtonSelector === 1}
          onClick={() => setStartDate('1w')}
        >1W
        </Button>

        <Button
          className='mr-1'
          variant='outline-primary'
          size='sm'
          active={dateButtonSelector === 2}
          onClick={() => setStartDate('2w')}
        >
          2W
        </Button>

        <Button
          className='mr-1'
          variant='outline-primary'
          size='sm'
          active={dateButtonSelector === 3}
          onClick={() => setStartDate('3w')}
        >
          3W
        </Button>

        <Button
          className='mr-1'
          variant='outline-primary'
          size='sm'
          active={dateButtonSelector === 4}
          onClick={() => setStartDate('1m')}
        >
          1M
        </Button>

        <Button
          className=''
          variant='outline-primary'
          size='sm'
          active={dateButtonSelector === 0}
          onClick={() => onCustomButtonClick()}
          aria-controls="collapse-datePicker"
          aria-expanded={dateButtonSelector === 0}
        >
          Custom
        </Button>
      </div>
      <Collapse in={dateButtonSelector === 0} mountOnEnter={true} unmountOnExit={true}>
        <div className='d-flex flex-column flex-md-row justify-content-start align-items-start'
             id="collapse-datePickers">
          <div className='mr-1'>
            <p className='mb-0'>Start date:</p>
            <DatePicker
              closeOnScroll={(e) => e.target === document}
              selected={filtersSelector.startDate}
              // selectsStart
              // startDate={filtersSelector.startDate}
              // endDate={filtersSelector.endDate}
              // minDate={filtersSelector.startDate}
              maxDate={filtersSelector.endDate}
              // locale="th"
              locale='gb'
              showTimeSelect
              timeFormat='p'
              timeIntervals={60}
              dateFormat='Pp'
              showDisabledMonthNavigation
              // disabledKeyboardNavigation
              onChange={(date) => {
                dispatch(action.setFilter('startDate', date))
                dispatch(action.setDateButtons(0))
              }}
            />
          </div>

          <div>
            <p className='mb-0'>End date:</p>
            <DatePicker
              closeOnScroll={(e) => e.target === document}
              selected={filtersSelector.endDate}
              // selectsEnd
              // startDate={filtersSelector.startDate}
              // endDate={filtersSelector.endDate}
              minDate={filtersSelector.startDate}
              maxDate={new Date()}
              // locale="th"
              locale='gb'
              showTimeSelect
              timeFormat='p'
              timeIntervals={60}
              dateFormat='Pp'
              showDisabledMonthNavigation
              // disabledKeyboardNavigation
              onChange={(date) => {
                dispatch(action.setFilter('endDate', date))
                dispatch(action.setDateButtons(0))
              }}
            />
          </div>
        </div>
      </Collapse>
    </>
  )
}

export default DateButtonBar
