import * as d3 from 'd3'

// const MARGIN = { TOP: 15, RIGHT: 35, BOTTOM: 55, LEFT: 35 };
const MARGIN = { TOP: 10, BOTTOM: 75, LEFT: 70, RIGHT: 10 }
const WIDTH = 800 - MARGIN.LEFT - MARGIN.RIGHT
const HEIGHT = 500 - MARGIN.TOP - MARGIN.BOTTOM
const AXIS_EXTRA = 5
// const MULTIPLIER = 0.95;
const TRANSITION_MSEC = 500

export default class InputCurrent {
  constructor (element, data) {
    const vis = this
    vis.element = element

    // append the svg object to the body of the page
    // append a 'group' element to 'svg'
    // moves the 'group' element to the top left margin

    vis.svg = d3.select(vis.element)
      .append('svg')
      .attr('width', WIDTH + MARGIN.LEFT + MARGIN.RIGHT)
      .attr('height', HEIGHT + MARGIN.TOP + MARGIN.BOTTOM)
      .append('g')
      .attr('transform', `translate(${MARGIN.LEFT},${MARGIN.TOP})`)

    // set the ranges
    vis.xScale = d3.scaleTime()
      .rangeRound([0, WIDTH])

    vis.yScale = d3.scaleLinear()
      .range([HEIGHT, 0])

    // add xAxis
    vis.xAxisGroup = vis.svg.append('g')
      .attr('transform', `translate(0, ${HEIGHT})`)

    // add yAxis
    vis.yAxisGroup = vis.svg.append('g')

    // add the x Label
    vis.svg.append('text')
      .attr('x', WIDTH / 2)
      .attr('y', HEIGHT + 60)
      .attr('font-size', 20)
      .attr('text-anchor', 'middle')
      .text('Time')

    // add the y Label
    vis.svg.append('text')
      .attr('x', -(HEIGHT / 2))
      .attr('y', -35)
      .attr('transform', 'rotate(-90)')
      .attr('font-size', 20)
      .attr('text-anchor', 'middle')
      .text('Input Voltage (V)')

    vis.update(data)
  }

  update (data) {
    const vis = this
    vis.data = data

    const xValue = (d) => d.loggedAt
    const yValue = (d) => d.inputCurrent

    // set the domains
    vis.xScale.domain(d3.extent(vis.data, xValue)).nice()

    vis.yScale.domain(d3.extent(vis.data, yValue)).nice()

    // custom x scale
    vis.xAxis = d3.axisBottom()
      .scale(vis.xScale)
    // .ticks(d3.timeHour.every(4))
      .tickFormat(vis.multiFormatDate)
      .tickSize(-(HEIGHT + AXIS_EXTRA))
      .tickPadding(AXIS_EXTRA)

    // render xAxis
    vis.xAxisGroup.attr('transform', `translate(0,${HEIGHT + AXIS_EXTRA})`)
      .transition().duration(TRANSITION_MSEC)
      .call(vis.xAxis)
      .selectAll('text')
      .style('text-anchor', 'end')
      .attr('dx', '-.8em')
      .attr('dy', '.15em')
      .attr('transform', 'rotate(-65)')

    // custom y scale
    const yAxis = d3.axisLeft()
      .scale(vis.yScale)
    // .ticks(8)
      .tickSize(-(WIDTH + AXIS_EXTRA))
      .tickPadding(AXIS_EXTRA)

    // render yAxis
    vis.yAxisGroup.attr('transform', `translate(${-AXIS_EXTRA},0)`)
      .transition().duration(TRANSITION_MSEC)
      .call(yAxis)

    // remove domain lines
    d3.selectAll('.domain').remove()

    // DATA JOIN
    const circles = vis.svg.selectAll('circle')
      .data(vis.data)

    // EXIT
    circles.exit()
      .transition().duration(TRANSITION_MSEC)
      .attr('cy', () => vis.yScale(0))
      .remove()

    // UPDATE
    circles.transition().duration(TRANSITION_MSEC)
      .attr('cx', (d) => vis.xScale(xValue(d)))
      .attr('cy', (d) => vis.yScale(yValue(d)))

    // ENTER
    circles.enter().append('circle')
      .attr('cy', vis.yScale(0))
      .attr('cx', (d) => vis.xScale(xValue(d)))
      .attr('r', 1)
      .attr('fill', '#0c4da2') // Thaitone 2.0 ครามฝรั่ง
      .transition().duration(TRANSITION_MSEC)
      .attr('cy', (d) => vis.yScale(yValue(d)))
  }

  multiFormatDate (date) {
    const vis = date
    vis.date = date

    const formatMillisecond = d3.timeFormat('.%L')
    const formatSecond = d3.timeFormat(':%S')
    const formatMinute = d3.timeFormat('%H:%M')
    const formatHour = d3.timeFormat('%H:00')
    const formatDay = d3.timeFormat('%e %b')
    const formatWeek = d3.timeFormat('%e %b')
    const formatMonth = d3.timeFormat('%b')
    const formatYear = d3.timeFormat('%Y')

    return (d3.timeSecond(vis.date) < vis.date ? formatMillisecond
      : d3.timeMinute(vis.date) < vis.date ? formatSecond
        : d3.timeHour(vis.date) < vis.date ? formatMinute
          : d3.timeDay(vis.date) < vis.date ? formatHour
            : d3.timeMonth(vis.date) < vis.date ? (d3.timeWeek(vis.date) < vis.date ? formatDay : formatWeek)
              : d3.timeYear(vis.date) < vis.date ? formatMonth
                : formatYear)(vis.date)
  }
}
