import React from 'react'
import { useSelector } from 'react-redux'
import Alert from 'react-bootstrap/Alert'

const StatusBar = () => {

  const sessionSelector = useSelector((state) => state.authorizationError.status)
  const queryErrorCountSelector = useSelector((state) => state.queryError.count)

  return (
    <>
      <Alert
        key='authorizationError'
        variant='warning'
        show={sessionSelector || queryErrorCountSelector > 2}
      >
        Authorisation Error, Please logout and login again.
      </Alert>
    </>
  )
}

export default StatusBar
