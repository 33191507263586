import * as d3 from 'd3'

const MARGIN = { TOP: 10, BOTTOM: 75, LEFT: 70, RIGHT: 15 }
const WIDTH = 800 - MARGIN.LEFT - MARGIN.RIGHT
const HEIGHT = 505 - MARGIN.TOP - MARGIN.BOTTOM
// const MULTIPLIER = 0.95;
// const BAR_PADDING = 0;
const MAX_Y_TICKS = 20
const TRANSITION_MSECS = 500
const DELAY_MSECS = 500
const LABEL_PADDING_TOP = 0

export default class SummaryHistogram {
  constructor (element, data) {
    const vis = this
    vis.element = element

    // append the svg object to the body of the page
    // append a 'group' element to 'svg'
    // moves the 'group' element to the top left margin

    vis.svg = d3.select(vis.element)
      .append('svg')
      .attr('width', WIDTH + MARGIN.LEFT + MARGIN.RIGHT)
      .attr('height', HEIGHT + MARGIN.TOP + MARGIN.BOTTOM)
      .append('g')
      .attr('transform', `translate(${MARGIN.LEFT},${MARGIN.TOP})`)

    // add xAxis
    vis.xAxisGroup = vis.svg.append('g')
      .attr('transform', `translate(0, ${HEIGHT})`)

    // add yAxis
    vis.yAxisGroup = vis.svg.append('g')

    // add the x Label
    vis.svg.append('text')
      .attr('x', WIDTH / 2)
      .attr('y', HEIGHT + 60)
      .attr('font-size', 20)
      .attr('text-anchor', 'middle')
      .text('Time in 24-hour pattern')

    // add the y Label
    vis.svg.append('text')
      .attr('x', -(HEIGHT / 2))
      .attr('y', -30)
      .attr('transform', 'rotate(-90)')
      .attr('font-size', 20)
      .attr('text-anchor', 'middle')
      .text('Frequency')

    // set the ranges
    vis.xScale = d3.scaleTime()
      .domain([new Date(0, 0, 0, 0, 0), new Date(0, 0, 0, 24, 0)])
      .rangeRound([0, WIDTH])
    // .range([0, WIDTH]);
    vis.yScale = d3.scaleLinear()
      .range([HEIGHT, 0])

    // calculate histogram thresholds
    const thresholds = vis.xScale.ticks(d3.timeHour)

    // set the parameters for the histogram
    vis.histogram = d3.bin()
      .value((d) => {
        return new Date(0, 0, 0, d.createdAt.getHours(), d.createdAt.getMinutes())
      })
      .domain(vis.xScale.domain())
      .thresholds(thresholds)

    vis.update(data)
  }

  update (data) {
    const vis = this
    vis.data = data

    // group the data for the bars
    const bins = vis.histogram(vis.data)

    // console.log("summary bins")
    // console.log(vis.bins)
    // console.log("summary bins end")

    // store max Y for Y domain and  Y ticks
    const maxY = +d3.max(bins, (d) => d.length)

    // Scale the range of the data in the y domain
    vis.yScale.domain([0, maxY])

    // custom x scale
    vis.xAxis = d3.axisBottom()
      .scale(vis.xScale)
      .ticks(24)
    // .tickSize(-HEIGHT)
      .tickFormat(d3.timeFormat('%H:%M'))

    // custom y scale
    vis.yAxis = d3.axisLeft()
      .scale(vis.yScale)
      .ticks(Math.min(maxY, MAX_Y_TICKS))
      .tickSize(-WIDTH)
      .tickFormat(d3.format('d'))

    // render xAxis
    vis.xAxisGroup.transition().duration(TRANSITION_MSECS).call(vis.xAxis)
      .selectAll('text')
      .style('text-anchor', 'end')
      .attr('dx', '-.8em')
      .attr('dy', '.15em')
      .attr('transform', 'rotate(-65)')

    // render yAxis
    vis.yAxisGroup.transition().duration(TRANSITION_MSECS).call(vis.yAxis)

    // DATA JOIN
    // append the bar rectangles to the svg element
    const entries = vis.svg.selectAll('g.entry')
      .data(bins)

    // EXIT
    // remove exceed entries (bar rectangles + label appended to the svg 'g' element)
    entries.each(function () { // the callback actually have 2 variables, datum and iterate
      d3.select(this).select('text.label')
        .exit()
        .transition().duration(TRANSITION_MSECS)
        .attr('opacity', '0')
        .text('')
        .remove()
      d3.select(this).select('rect.bin')
        .exit()
        .transition().duration(TRANSITION_MSECS)
        .attr('height', 0)
        .attr('y', HEIGHT)
        .remove()
    })
    entries.exit().remove()

    // UPDATE
    // update the entries that haven't been removed (bar rectangles + label appended to the svg 'g' element)
    entries.each(function () { // the callback actually have 2 variables, datum and iterate
      d3.select(this).select('rect.bin')
        .transition().duration(TRANSITION_MSECS)
        .attr('transform', (d) => `translate(${vis.xScale(d.x0)},${vis.yScale(d.length)})`)
        .attr('width', (d) => vis.xScale(d.x1) - vis.xScale(d.x0))
        .attr('height', (d) => HEIGHT - vis.yScale(d.length))
      d3.select(this).select('text.label')
        .attr('x', (d) => (vis.xScale(d.x0) + (vis.xScale(d.x1) - vis.xScale(d.x0)) / 2))
        .attr('y', (d) => vis.yScale(d.length) + LABEL_PADDING_TOP)
        .attr('opacity', '0')
        .text('')
        .transition().duration(TRANSITION_MSECS).delay(DELAY_MSECS)
        .attr('opacity', '1')
        .text((d) => (+d.length) > 0 ? d.length : '')
    })

    // ENTER
    // append the entries (bar rectangles + label appended to the svg 'g' element) at the first time
    // or then there are new entries
    entries.enter().append('g')
      .attr('class', 'entry')
      .each(function () { // the callback actually have 2 variables, datum and iterate
        d3.select(this).append('rect')
          .attr('class', 'bin')
          .attr('fill', '#AC83A8') // Thai tone 2.0 นิลุบล
          .attr('stroke', 'white')
          .attr('stroke-width', 0.5)
          .attr('transform', (d) => `translate(${vis.xScale(d.x0)},${vis.yScale(d.length)})`)
          .transition().duration(TRANSITION_MSECS)
          .attr('width', (d) => vis.xScale(d.x1) - vis.xScale(d.x0))
          .attr('height', (d) => HEIGHT - vis.yScale(d.length))
        d3.select(this).append('text')
          .attr('class', 'label')
          .attr('x', (d) => (vis.xScale(d.x0) + (vis.xScale(d.x1) - vis.xScale(d.x0)) / 2))
          .attr('y', (d) => vis.yScale(d.length) + LABEL_PADDING_TOP)
          .attr('opacity', '0')
          .transition().duration(TRANSITION_MSECS).delay(DELAY_MSECS)
          .attr('opacity', '1')
          .text((d) => (+d.length) > 0 ? d.length : '')
      })
  }
}
