import React from 'react'

const LogRow = (props) => {
  return (
    <tr className='LogRow'>
      {/* both */}
      <td>{props.item?.loggedAt?.toLocaleString('en-GB')}</td>
      <td>{props.item?.createdAt?.toLocaleString('en-GB')}</td>
      <td>{props.item?.rasPiType}</td>
      <td>{props.item?.rasPiId}</td>
      <td>{props.item?.inputVoltage?.toFixed(3)}</td>
      <td>{props.item?.inputCurrent?.toFixed(3)}</td>
      <td>{props.item?.envTemperature?.toFixed(1)}</td>
      <td>{props.item?.temperature?.toFixed(1)}</td>
      <td>{props.item?.lastReceivedCamId}</td>
      <td>{props.item?.lastReceivedImgIndex}</td>
      {/* only rx */}
      {
                props.filters.rasPiType !== 'Tx' &&
                  <>
                    <td>{(props.item.lastReceivedDate) ? props.item.lastReceivedDate.toLocaleString('en-GB') : ''}</td>
                  </>
            }
      {/* only tx */}

      {
                props.filters.rasPiType !== 'Rx' &&
                  <>
                    <td>{(props.item.lastSentDate) ? props.item.lastSentDate.toLocaleString('en-GB') : ''}</td>
                    <td>{props.item?.lastSentCamId}</td>
                    <td>{props.item?.lastSentImgIndex}</td>
                    <td>{(props.item.lastCamActiveDate) ? props.item.lastCamActiveDate.toLocaleString('en-GB') : ''}</td>
                  </>
            }

    </tr>
  )
}

export default LogRow
