import { useSelector } from 'react-redux'
import Container from 'react-bootstrap/Container'
import FilterBar from '../bars/FilterBar'
import Stats from './Stats/Stats'
import Login from './Login'

const StatsMain = (props) => {
  const userSelector = useSelector((state) => state.user)

  return (
    <Container fluid>
      {
                (userSelector?.role === 'admin' || userSelector?.role === 'user')
                  ? <>
                    <FilterBar />
                    <Stats />
                  </>
                  : <Login />
            }
    </Container>
  )
}

export default StatsMain
