import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import Navbar from 'react-bootstrap/Navbar'
import Container from 'react-bootstrap/Container'
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup'
import ToggleButton from 'react-bootstrap/ToggleButton'
import localStorageService from '../../../services/localStorageService'
import action from '../../../redux/action'
import LogFilters from '../LogFilters'
import TemperatureWrapper from './Temperature/TemperatureWrapper'
import EnvTemperatureWrapper from './EnvTemperature/EnvTemperatureWrapper'
import InputVoltageWrapper from './InputVoltage/InputVoltageWrapper'
import InputCurrentWrapper from './InputCurrent/InputCurrentWrapper'

const LogGraphs = (props) => {
  const BACKEND_SOCKET = process.env.REACT_APP_BACKEND_SOCKET || 'http://localhost:3001'
  // const RELOAD_LOGS_MSEC = Number(process.env.REACT_APP_RELOAD_LOGS_MSEC) || 6e4;
  const DAY_RANGE_LOGS = Number(process.env.REACT_APP_DAY_RANGE_LOGS) || 7

  const [dataById, setDataById] = useState([])
  const [graphs, setGraphs] = useState([])
  const [toggleValue, setToggleValue] = useState(0)
  const [filters, setFilters] = useState(() => {
    const startDate = new Date()
    const now = new Date()

    return {
      rasPiType: 'Tx+Rx',
      rasPiId: 'All',
      startDate: new Date(startDate.setDate(now.getDate() - DAY_RANGE_LOGS)),
      endDate: now
    }
  })

  const dispatch = useDispatch()

  const history = useHistory()

  useEffect(() => {
    if (!props.isActive) {
      return
    }

    let timer
    const fetcher = async () => {
      const filterString = `rasPiType=${filters.rasPiType}&rasPiId=${filters.rasPiId}&startDate=${filters.startDate}&endDate=${filters.endDate}`

      try {
        const res = await fetch(`${BACKEND_SOCKET}/api/logs/log_graphs?${filterString}`, {
          method: 'get',
          headers: {
            'content-type': 'application/json;charset=UTF-8',
            'Authorization': 'Bearer ' + localStorageService.getToken()
          }
        })

        if (res.status === 200) {
          const data = await res.json()

          // console.log(data)

          setDataById(data)
        } else if (res.status === 401) {
          // console.log("Session timeout.");

          dispatch({
            type: 'AUTHORIZATION_ERROR'
          })
          dispatch(action.logout()).then(() => {
              history.push('/login')
              // return () => clearTimeout(timer)
            }
          )
        } else {
          console.log('Error on something else.')
        }
      } catch (err) {

        console.log(`error: ${err}`)
      }

      // timer = setTimeout(() => {
      //     fetcher();
      // }, RELOAD_LOGS_MSEC);
    }

    fetcher().then()

    return () => clearTimeout(timer)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, props.isActive])

  useEffect(() => {
    dispatch(action.queryUserDb())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onFilterTypeClick = (x) => {
    setToggleValue(0)
    setGraphs([])
    setFilters({ ...filters, rasPiType: x })
  }

  const onFilterRasPiIdClick = (x) => {
    setToggleValue(0)
    setGraphs([])
    setFilters({ ...filters, rasPiId: x })
  }

  const onFilterStartDateClick = (date) => {
    setToggleValue(0)
    setGraphs([])
    setFilters({ ...filters, startDate: new Date(date.setSeconds(0, 0)) })
  }

  const onFilterEndDateClick = (date) => {
    setToggleValue(0)
    setGraphs([])
    setFilters({ ...filters, endDate: new Date(date.setSeconds(0, 0)) })
  }

  const renderGraphButtons = () => {
    if (dataById.length > 0) {
      let i = 1
      const buttons = dataById.map((item) => {
        return (
          <ToggleButton
            key={item.key}
            // className="mr-1"
            variant={(graphs[0]?.id === item.key) ? 'primary' : 'outline-primary'}
            size='sm'
            value={i++}
            onClick={() => setGraphs(item.value)}
          >
            {item.key}
          </ToggleButton>
        )
      })

      return (
        <ToggleButtonGroup
          type='radio'
          name='options'
          value={toggleValue}
          onChange={(val) => setToggleValue(val)}
        >
          {buttons}
        </ToggleButtonGroup>
      )
    } else {
      return 'There are no data available. Please try editing the filters.'
    }
  }

  const renderTemperatureWrapper = () => {
    if (dataById.length === 0) {
      return 'There are no data available. Please try editing the filters.'
    }

    if (graphs.length === 0) {
      return 'Please select data from the button(s) above.'
    }

    return (
      <>
        <TemperatureWrapper data={graphs}/>
      </>
    )
  }

  const renderEnvTemperatureWrapper = () => {
    if (dataById.length === 0) {
      return 'There are no data available. Please try editing the filters.'
    }

    if (graphs.length === 0) {
      return 'Please select data from the button(s) above.'
    }

    return (
      <>
        <EnvTemperatureWrapper data={graphs}/>
      </>
    )
  }

  const renderInputVoltageWrapper = () => {
    if (dataById.length === 0) {
      return 'There are no data available. Please try editing the filters.'
    }

    if (graphs.length === 0) {
      return 'Please select data from the button(s) above.'
    }

    return (
      <>
        <InputVoltageWrapper data={graphs}/>
      </>
    )
  }

  const renderInputCurrentWrapper = () => {
    if (dataById.length === 0) {
      return 'There are no data available. Please try editing the filters.'
    }

    if (graphs.length === 0) {
      return 'Please select data from the button(s) above.'
    }

    return (
      <>
        <InputCurrentWrapper data={graphs}/>
      </>
    )
  }

  const getGraphTitle = () => {
    if (toggleValue > 0) {
      return ` of ${dataById[toggleValue - 1].key}`
    }
  }

  return (
    <div className='Logs'>
      <Container fluid>
        <LogFilters
          filters={filters}
          onFilterTypeClick={(x) => onFilterTypeClick(x)}
          onFilterRasPiIdClick={(x) => onFilterRasPiIdClick(x)}
          onFilterStartDateClick={(date) => onFilterStartDateClick(date)}
          onFilterEndDateClick={(date) => onFilterEndDateClick(date)}
        />
        <p className='mt-3 mb-1'>Available graph(s):</p>
        <div>
          {
            renderGraphButtons()
          }
        </div>

        <Navbar className='mt-3' bg='light'>
          <Navbar.Brand>CPU Temperature{getGraphTitle()}</Navbar.Brand>
        </Navbar>
        {renderTemperatureWrapper()}

        <Navbar className='mt-3' bg='light'>
          <Navbar.Brand>Env Temperature{getGraphTitle()}</Navbar.Brand>
        </Navbar>
        {renderEnvTemperatureWrapper()}

        <Navbar className='mt-1' bg='light'>
          <Navbar.Brand>Input Voltage{getGraphTitle()}</Navbar.Brand>
        </Navbar>
        {renderInputVoltageWrapper()}

        <Navbar className='mt-1' bg='light'>
          <Navbar.Brand>Input Current{getGraphTitle()}</Navbar.Brand>
        </Navbar>
        {renderInputCurrentWrapper()}
      </Container>
    </div>
  )
}

export default LogGraphs
