import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import action from '../../redux/action'

const AddUserForm = (props) => {
  const [formState, setFormState] = useState(
    {
      formElements: {
        username: {
          type: 'text',
          value: '',
          validator: {
            required: true,
            minLength: process.env.USERNAME_MIN_LENGTH || 4,
            maxLength: process.env.USERNAME_MAX_LENGTH || 8,
            pattern: 'username'
          },
          touched: true,
          error: { status: true, message: '' }
        },
        password: {
          type: 'password',
          value: '',
          validator: {
            required: true,
            minLength: process.env.PASSWORD_MIN_LENGTH || 4,
            pattern: 'password'
          },
          touched: true,
          error: { status: true, message: '' }
        },
        role: {
          value: '',
          validator: {
            required: true
          },
          touched: true,
          error: { status: true, message: '' }
        }
      },
      formValid: false
    }
  )

  const dispatch = useDispatch()

  const onFormChange = (e) => {
    const name = e.target.name
    const value = e.target.value
    const updatedForm = { ...formState.formElements }
    updatedForm[name].value = value
    updatedForm[name].touched = true
    const validatorObject = checkValidator(value, updatedForm[name].validator)
    updatedForm[name].error = {
      status: validatorObject.status,
      message: validatorObject.message
    }
    let formStatus = true
    for (const name in updatedForm) {
      if (updatedForm[name].validator.required) {
        formStatus = !updatedForm[name].error.status && formStatus
      }
    }
    setFormState((formState, props) => {
      return (
        {
          ...formState,
          formElements: updatedForm,
          formValid: formStatus
        }
      )
    })
  }

  const onFormSubmit = (e) => {
    e.preventDefault()
    const formData = {}

    for (const name in formState.formElements) {
      formData[name] = formState.formElements[name].value
    }

    submit(formData)
  }

  const checkValidator = (value, rule) => {
    let valid = true
    let message = ''

    if (value.trim().length === 0 && rule.required) {
      valid = false
      message = 'Required.'
    }
    if (value.length < rule.minLength && valid) {
      valid = false
      message = `Less than ${rule.minLength} characters.`
    }
    if (value.length > rule.maxLength && valid) {
      valid = false
      message = `Greater than ${rule.maxLength} characters.`
    }
    if (rule.pattern === 'username' && valid) {
      if (!/^[a-z0-9]*$/.test(value)) {
        valid = false
        message += 'Only lower-case Latin letters and numbers.'
      }
      if (/(admin)/.test(value)) {
        valid = false
        message += 'Reserved username.'
      }
      if (formState.formElements.role.value === 'rasPi') {
        if (!/^(undp)[0-9]{1,2}(rx)/.test(value)) {
          valid = false
          message += 'wrong rasPi name'
        }
      }
    }
    if (rule.pattern === 'password' && valid) {
      if (!/(?=.*[a-z])/.test(value)) {
        valid = false
        message += 'At least 1 lower-case Latin letter.'
      }
      if (!/(?=.*[A-Z])/.test(value)) {
        valid = false
        message += '\nAt least 1 upper-case Latin letter.'
      }
      if (!/(?=.*[0-9])/.test(value)) {
        valid = false
        message += '\nAt least 1 digit.'
      }
    }

    return { status: !valid, message: message }
  }

  const getInputClass = (name) => {
    const elementErrorStatus = formState.formElements[name].error.status

    return elementErrorStatus && formState.formElements[name].touched
      ? 'form-control is-invalid'
      : 'form-control is-valid'
  }

  const getErrorMessage = (name) => formState.formElements[name].error.message

  const clearForm = () => {
    const updatedForm = { ...formState.formElements }

    let formStatus

    for (const name in updatedForm) {
      updatedForm[name].value = ''
      updatedForm[name].touched = true

      const validatorObject = checkValidator('', updatedForm[name].validator)
      updatedForm[name].error = {
        status: validatorObject.status,
        message: validatorObject.message
      }

      formStatus = true

      if (updatedForm[name].validator.required) {
        formStatus = !updatedForm[name].error.status && formStatus
      }
    };

    setFormState((formState, props) => {
      return (
        {
          ...formState,
          formElements: updatedForm,
          formValid: formStatus
        }
      )
    })
  }

  const submit = (formData) => {
    dispatch(action.createUser(formData)).then(()=>{
      clearForm()
      props.setRenderTrigger(!props.renderTrigger)
    })
    // console.log(formData);
  }

  return (

    <Form className='AddUserForm mt-2 mb-3' onSubmit={onFormSubmit}>
      <Form.Row>
        <Col xs={4}>
          <Form.Group controlId='role'>
            <Form.Label>Role</Form.Label>
            <Form.Control
              className={getInputClass('role')}
              size='sm'
              as='select'
              name='role'
              value={formState.formElements.role.value}
              onChange={onFormChange}
            >
              <option value=''>Select role...</option>
              <option value='rasPi'>rasPi</option>
              <option value='user'>user</option>
              <option value='admin'>admin</option>
            </Form.Control>
            <div className='invalid-feedback'>
              {getErrorMessage('role')}
            </div>
          </Form.Group>
        </Col>
        <Col xs={4}>
          <Form.Group controlId='username'>
            <Form.Label>Username</Form.Label>
            <Form.Control
              className={getInputClass('username')}
              size='sm'
              type='text'
              placeholder='Username'
              name='username'
              value={formState.formElements.username.value}
              onChange={onFormChange}
            />
            <div className='invalid-feedback'>
              {getErrorMessage('username')}
            </div>
          </Form.Group>
        </Col>
        <Col xs={4}>
          <Form.Group controlId='password'>
            <Form.Label>Password</Form.Label>
            <Form.Control
              className={getInputClass('password')}
              size='sm'
              type='password'
              placeholder='Password'
              name='password'
              value={formState.formElements.password.value}
              onChange={onFormChange}
            />
            <div className='invalid-feedback'>
              {getErrorMessage('password')}
            </div>
          </Form.Group>
        </Col>
      </Form.Row>
      <Button
        className='d-block ml-auto mr-0'
        variant='primary'
        size='sm' type='submit'
        disabled={!formState.formValid}
      >
        Add user
      </Button>
    </Form>

  )
}

export default AddUserForm
