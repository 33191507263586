import React, { useRef, useState, useEffect } from 'react'
import Histogram from './Histogram'

const HistogramWrapper = (props) => {
  const chartArea = useRef(null)
  const [chart, setChart] = useState(null)

  useEffect(() => {
    if (!chart) {
      setChart(new Histogram(chartArea.current, props.data, props.interval))
    } else {
      chart.update(props.data, props.interval)
    }
  }, [chart, props.data, props.interval])

  return (
    <div className='histogram-area' ref={chartArea} />
  )
}

export default HistogramWrapper
