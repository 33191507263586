import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Table from 'react-bootstrap/Table'
import Button from 'react-bootstrap/Button'
import RasPiBar from './FilterBar/RasPiBar'
import DateButtonBar from './FilterBar/DateButtonBar'
import ControlPanel from './FilterBar/ControlPanel'
import action from '../redux/action'

const FilterBar = () => {
  const [active, setActive] = useState(false)

  const showFiltersSelector = useSelector((state) => state.showFilters)

  const dispatch = useDispatch()

  const onFilterButtonClick = () => {
    setActive(!active)
    dispatch(action.setShowFilters(!showFiltersSelector))
  }

  return (
    <>
      <Table borderless='true' size='sm'>
        <tbody>
          <tr>
            <td>Camera</td>
            <td><RasPiBar /></td>
          </tr>
          <tr>
            <td>Period</td>
            <td><DateButtonBar /></td>
          </tr>
          <tr>
            <td />
            <td>
              <Button
                className='d-block ml-0 mr-auto'
                variant='outline-success'
                size='sm'
                style={{ width: '96px' }}
                active={active}
                onClick={() => onFilterButtonClick()}
                aria-controls="collapse-controlPanel"
                aria-expanded={active}
              >
                {showFiltersSelector ? 'Less details' : 'More details'}
              </Button>
            </td>
          </tr>
        </tbody>
      </Table>

      <ControlPanel />

    </>
  )
}

export default FilterBar
