import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import Button from 'react-bootstrap/Button'
import action from '../../../redux/action'
import localStorageService from '../../../services/localStorageService'

const CSVButton = (props) => {
  const BACKEND_SOCKET = process.env.REACT_APP_BACKEND_SOCKET || 'http://localhost:3001'

  const [loadingCSV, setLoadingCSV] = useState(false)

  const dispatch = useDispatch()

  const history = useHistory()

  const downloadCsv = async () => {
    setLoadingCSV(true)
    try {
      const res = await fetch(`${BACKEND_SOCKET}/api/logs/log_csv?${props.filterString}`, {
        method: 'get',
        headers: {
          'content-type': 'text/csv;charset=UTF-8',
          'Authorization': 'Bearer ' + localStorageService.getToken()
        }
      })

      if (res.status === 200) {
        const blob = await res.blob()

        // console.log(blob)

        const url = window.URL.createObjectURL(new Blob([blob]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'data.csv')

        document.body.appendChild(link)

        link.click()

        link.parentNode.removeChild(link)
      } else if (res.status === 401) {
        // console.log("Session timeout.");

        dispatch({
          type: 'AUTHORIZATION_ERROR'
        })

        dispatch(action.logout()).then(() => {
            history.push('/login')
          }
        )
      } else {
        console.log('Error on something else.')
      }
    } catch (err) {
      console.log(err)
    } finally {
      setLoadingCSV(false)
      return
    }
  }

  return (
    <div className='csvButton'>
      <Button
        variant='primary'
        size='sm'
        active={!loadingCSV}
        onClick={() => downloadCsv()}
      >
        {
          (loadingCSV) ? 'Please wait.' : 'Download CSV'
        }
      </Button>
    </div>
  )
}

export default CSVButton
