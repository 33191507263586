import React, { useRef, useState, useEffect } from 'react'
import Temperature from './Temperature'

const TemperatureWrapper = (props) => {
  const chartArea = useRef(null)
  const [chart, setChart] = useState(null)

  useEffect(() => {
    if (!chart) {
      setChart(new Temperature(chartArea.current, props.data))
    } else {
      chart.update(props.data)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chart, props.data])

  return (
    <div className='temperature-area' ref={chartArea} />
  )
}

export default TemperatureWrapper
