import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Loader from 'react-loader-spinner'
import action from '../../redux/action'
import Poster from './History/Poster'
import localStorageService from '../../services/localStorageService'

const History = () => {
  const BACKEND_SOCKET = process.env.REACT_APP_BACKEND_SOCKET || 'http://localhost:3001'
  // data fetching cycle in milliseconds
  // const RELOAD_MSEC = process.env.REACT_APP_RELOAD_MSEC || 6e4

  const [isLoading, setIsLoading] = useState(false)

  const filtersSelector = useSelector((state) => state.filters)

  const dispatch = useDispatch()

  useEffect(() => {
    // let timer
    const fetcher = async () => {
      setIsLoading(true)

      const filterString = `startDate=${filtersSelector.startDate}&endDate=${filtersSelector.endDate}&isLiked=${filtersSelector.isLiked}&isHidden=${filtersSelector.isHidden}&isHuman=${filtersSelector.isHuman}&isWeapon=${filtersSelector.isWeapon}&isWildlife=${filtersSelector.isWildlife}&isDomestic=${filtersSelector.isDomestic}&tag1=${filtersSelector.tag1}&tag2=${filtersSelector.tag2}&tag3=${filtersSelector.tag3}&rasPiId=${filtersSelector.rasPiId}`

      // console.log('fetching, startDate is')
      // console.log(filtersSelector.startDate)

      try {
        const res = await fetch(`${BACKEND_SOCKET}/api/imgs/img_data?${filterString}`, {
          method: 'get',
          headers: {
            'content-type': 'application/json;charset=UTF-8',
            'Authorization': 'Bearer ' + localStorageService.getToken()
          }
        })
        if (res.status === 200) {
          const data = await res.json()

          dispatch({
            type: 'QUERY',
            payload: data
          })
          setIsLoading(false)
          // timer = setTimeout(() => fetcher(), RELOAD_MSEC)
        } else if (res.status === 401) {
          // console.log("Session timeout.");
          dispatch(action.logout()).then(() => dispatch({
              type: 'AUTHORIZATION_ERROR'
            })
          )
        } else {
          console.log('Error on something else.')
          dispatch({
            type: 'QUERY_ERROR'
          })
        }
      } catch (err) {
        dispatch({
          type: 'QUERY_ERROR'
        })
        console.log(`error: ${err}`)
      }

    }

    fetcher().then()

    // return () => clearTimeout(timer)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtersSelector])

  const queryIdArr = useSelector((state) => {
    const arr = []
    state.query.data.forEach((doc) => {
      arr.push({ _id: doc._id, isHidden: doc.isHidden })
    })
    return arr
  })

  const renderFeed = () => {
    if (queryIdArr.length === 0) {
      return 'No data to show. Please select different time or try editing the filters.'
    }

    return (
      <Row>
        {
          queryIdArr.map((item) => (
            (filtersSelector.isHidden === item.isHidden) &&
            <Col key={item._id} xs={12} md={6} xl={4}>
              <Poster
                _id={item._id}
              />
            </Col>
          ))
        }
      </Row>
    )
  }

  const renderLoader = () => {
    return (

      <div className="d-flex justify-content-center" style={{ position: 'relative', top: '100px' }}>
        <Loader
          type="Grid"
          color="#44A545"
          height={80}
          width={80}
        />
      </div>

    )
  }

  return (

    <Container fluid>
      {
        isLoading ?
          renderLoader()
          :
          renderFeed()
      }
    </Container>
  )
}

export default History
