import React from 'react'
import Table from 'react-bootstrap/Table'
import LogRow from './LogRow'

const LogTable = (props) => {
  return (
    <div className='LogTable mt-2'>
      <Table size='sm' responsive striped>
        <thead>
          <tr>
            {/* both */}
            <th>Rx Time</th>
            <th>Server Time</th>
            <th>Type</th>
            <th>RasPiId</th>
            <th><i>V</i><sub>in</sub> (V)</th>
            <th><i>I</i><sub>in</sub> (A)</th>
            <th><i>T</i><sub>Env</sub> ({'\u2103'})</th>
            <th><i>T</i><sub>CPU</sub> ({'\u2103'})</th>
            <th>LastRecvCamId</th>
            <th>LastRecvImgIdx</th>
            {/* only rx */}
            {
                            props.filters.rasPiType !== 'Tx' &&
                              <>
                                <th>LastReceivedDate</th>
                              </>
                        }
            {/* only tx */}
            {
                            props.filters.rasPiType !== 'Rx' &&
                              <>
                                <th>LastSentDate</th>
                                <th>LastSentCamId</th>
                                <th>LastSentImgIdx</th>
                                <th>LastCamActiveDate</th>
                              </>
                        }
          </tr>
        </thead>
        <tbody>

          {
                        props.logs.map((item) => (
                          <LogRow key={item._id} item={item} filters={props.filters} />
                        ))
                    }
        </tbody>
      </Table>
    </div>
  )
}

export default LogTable
