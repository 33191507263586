import React from 'react'
import PropTypes from 'prop-types'
import Badge from 'react-bootstrap/Badge'
import Table from 'react-bootstrap/Table'

// RasPi temperature threshold.
const RASPI_TEMP_RED = Number(process.env.REACT_APP_RASPI_TEMP_RED) || 82
const RASPI_TEMP_ORANGE = Number(process.env.REACT_APP_RASPI_TEMP_ORANGE) || 72
const RASPI_TEMP_YELLOW = Number(process.env.REACT_APP_RASPI_TEMP_YELLOW) || 62

const TemperatureStatus = ({ txTemperature, rxTemperature }) => {
  const getBadgeClass = (temperature) => {
    if (temperature === 0) {
      return 'status-badge-black'
    } else if (temperature >= RASPI_TEMP_RED) {
      return 'status-badge-red'
    } else if (temperature >= RASPI_TEMP_ORANGE) {
      return 'status-badge-orange'
    } else if (temperature >= RASPI_TEMP_YELLOW) {
      return 'status-badge-yellow'
    } else {
      return 'status-badge-green'
    }
  }

  return (
    <Table size='sm' borderless>
      <tbody>
        <tr>
          {/* tx */}
          <td>
            <Badge className={`d-inline ${getBadgeClass(txTemperature)}`}>
              {(txTemperature === 0 || isNaN(txTemperature)) ? 'N/A' : `${txTemperature.toFixed(1)}\u2103`}
            </Badge>
          </td>
        </tr>
        <tr>
          {/* rx */}
          <td>
            <Badge className={`d-inline ${getBadgeClass(rxTemperature)}`}>
              {(rxTemperature === 0 || isNaN(rxTemperature)) ? 'N/A' : `${rxTemperature.toFixed(1)}\u2103`}
            </Badge>
          </td>
        </tr>
      </tbody>
    </Table>
  )
}

TemperatureStatus.defaultProps = {
  tx: 4,
  rx: 4
}

TemperatureStatus.propTypes = {
  tx: PropTypes.number,
  rx: PropTypes.number
}

export default TemperatureStatus
