import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
// import Container from 'react-bootstrap/Container'
import Table from 'react-bootstrap/Table'
import action from '../../redux/action'
import localStorageService from '../../services/localStorageService'
import RasPiStatus from './Widgets/RasPiStatus'
import ImageStatus from './Widgets/ImageStatus'
import TemperatureStatus from './Widgets/TemperatureStatus'

const StatusTable = () => {
  const BACKEND_SOCKET = process.env.REACT_APP_BACKEND_SOCKET || 'http://localhost:3001'
  const RELOAD_HEARTBEAT_MIN = Number(process.env.REACT_APP_RELOAD_HEARTBEAT_MIN) || 5

  // an amount of minutes before this component warns user when rasPiTx doesn't response.
  const MAX_TX_INACTIVE_MIN = Number(process.env.REACT_APP_MAX_TX_INACTIVE_MIN) || 360

  // an amount of minutes before this component warns user when rasPiRx doesn't response.
  const MAX_RX_INACTIVE_MIN = Number(process.env.REACT_APP_MAX_RX_INACTIVE_MIN) || 180

  // an amount of hours before the badge turns yellow.
  const IMG_LAST_SEEN_HOURS_NORMAL = Number(process.env.REACT_APP_IMG_LAST_SEEN_HOURS_NORMAL) || 24
  // an amount of hours before the badge turns red.
  const IMG_LAST_SEEN_HOURS_WARNING = Number(process.env.REACT_APP_IMG_LAST_SEEN_HOURS_WARNING) || 48

  // const [userInfo, setUserInfo] = useState({});
  const [imgStatus, setImgStatus] = useState([])
  const [rasPiStatus, setRasPiStatus] = useState({})

  const userSelector = useSelector((state) => state.user)
  const rasPiIdSelector = useSelector((state) => state.rasPis.rasPiIds)

  const dispatch = useDispatch()

  const history = useHistory()

  useEffect(() => {
    let timer
    const fetcher = async () => {
      if (!userSelector) {
        return
      }

      dispatch(action.queryRasPiName()).then(async () => {
        // fetch rasPiStatus
        try {
          const res = await fetch(`${BACKEND_SOCKET}/api/logs/log_lastStatus`, {
            method: 'get',
            headers: {
              'content-type': 'application/json;charset=UTF-8',
              'Authorization': 'Bearer ' + localStorageService.getToken()
            }
          })
          if (res.status === 200) {
            const data = await res.json()

            // console.log(data);

            setRasPiStatus(data)

          } else if (res.status === 401) {
            // console.log('Session timeout.')

            dispatch({
              type: 'AUTHORIZATION_ERROR'
            })

            dispatch(action.logout()).then(() => {
                history.push('/login')
                return () => clearTimeout(timer)
              }
            )
          } else {
            console.log('Error on something else.')
          }
        } catch (err) {
          console.log(`error: ${err}`)
          return
        }

        // fetchImgStatus

        try {
          const res = await fetch(`${BACKEND_SOCKET}/api/imgs/img_latestStatus`, {
            method: 'get',
            headers: {
              'content-type': 'application/json;charset=UTF-8',
              'Authorization': 'Bearer ' + localStorageService.getToken()
            }
          })

          if (res.status === 200) {
            const data = await res.json()

            // console.log("imgStatus")
            // console.log(data)
            setImgStatus(data)
          } else if (res.status === 401) {
            // console.log('Session timeout.')

            dispatch({
              type: 'AUTHORIZATION_ERROR'
            })

            dispatch(action.logout()).then(() => {
                history.push('/login')
                return () => clearTimeout(timer)
              }
            )

            return () => clearTimeout(timer)

          } else {
            console.log('Error on something else.')
          }
        } catch (err) {
          console.log(`error: ${err}`)
        }

        timer = setTimeout(() => fetcher(), RELOAD_HEARTBEAT_MIN * 6e4)
      })
    }

    fetcher().then()

    return () => clearTimeout(timer)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userSelector])

  const isRasPiUp = (type, minutes) => {
    if (type === 'tx') {
      return minutes < MAX_TX_INACTIVE_MIN
    } else if (type === 'rx') {
      return minutes < MAX_RX_INACTIVE_MIN
    } else {
      return false
    }
  }

  const getImgStatusCode = (hours) => {
    if (hours < IMG_LAST_SEEN_HOURS_NORMAL) {
      return 1
    } else if (hours < IMG_LAST_SEEN_HOURS_WARNING) {
      return 2
    } else {
      return 3
    }
  }

  const getRasPiStatus = (id, isComponent) => {
    if (Object.keys(rasPiStatus).length === 0) {
      return <p>loading</p>
    } else {
      const now = new Date()

      let txMinutes = MAX_TX_INACTIVE_MIN
      let rxMinutes = MAX_RX_INACTIVE_MIN

      rasPiStatus.tx.some((item) => {
        if (item._id === id) {
          txMinutes = Math.floor((now.getTime() - Number(item.loggedAt)) / 6e4)
          // console.log(`txMinutes ${id}: ${txMinutes} minutes ago.`)
          return true
        }

        return false
      })

      rasPiStatus.rx.some((item) => {
        if (item._id === id) {
          rxMinutes = Math.floor((now.getTime() - Number(item.loggedAt)) / 6e4)
          // console.log(`rxMinutes ${id}: ${rxMinutes} minutes ago.`)
          return true
        }

        return false
      })

      // Results shown on RasPiStatus component
      if (isComponent) {
        return (
          <>
            <RasPiStatus tx={isRasPiUp('tx', txMinutes)} rx={isRasPiUp('rx', rxMinutes)}/>
          </>
        )
      }
      // Results returned as an array
      return [isRasPiUp('tx', txMinutes), isRasPiUp('rx', rxMinutes)]
    }
  }

  const getImgStatus = (id) => {
    if (imgStatus.length < 1) {
      return <p>loading</p>
    } else {
      const now = new Date()

      let hours = IMG_LAST_SEEN_HOURS_WARNING

      imgStatus.some((item) => {
        if (item._id === id) {
          hours = Math.floor((now.getTime() - Number(item.sentAt)) / 36e5)
          // console.log(`sentAt ${id}: ${hours} hours ago.`)
          return true
        }

        return false
      })

      return (
        <ImageStatus rxTime={getImgStatusCode(hours)}/>
      )
    }
  }

  const getEnvTemperature = (id) => {
    // because Object.keys(new Date()).length === 0;
    // we have to do some additional check
    if (Object.keys(rasPiStatus).length === 0 && rasPiStatus.constructor === Object) {
      return <p>loading</p>
    } else {
      const [txIsUp, rxIsUp] = getRasPiStatus(id, false)

      let txTemperature = 0
      let rxTemperature = 0

      if (txIsUp) {
        rasPiStatus.tx.some((item) => {
          if (item._id === id) {
            txTemperature = Number(item.envTemperature)
            // console.log(`txTemperature ${id}: ${txTemperature} Celsius.`)
            return true
          }

          return false
        })
      }

      if (rxIsUp) {
        rasPiStatus.rx.some((item) => {
          if (item._id === id) {
            rxTemperature = Number(item.envTemperature)
            // console.log(`rxTemperature ${id}: ${rxTemperature} Celsius.`)
            return true
          }

          return false
        })
      }

      return (
        <>
          <TemperatureStatus txTemperature={txTemperature} rxTemperature={rxTemperature}/>
        </>
      )
    }
  }

  const getRasPiTemperature = (id) => {
    // because Object.keys(new Date()).length === 0;
    // we have to do some additional check
    if (Object.keys(rasPiStatus).length === 0 && rasPiStatus.constructor === Object) {
      return <p>loading</p>
    } else {
      const [txIsUp, rxIsUp] = getRasPiStatus(id, false)

      let txTemperature = 0
      let rxTemperature = 0

      if (txIsUp) {
        rasPiStatus.tx.some((item) => {
          if (item._id === id) {
            txTemperature = Number(item.temperature)
            // console.log(`txTemperature ${id}: ${txTemperature} Celsius.`)
            return true
          }

          return false
        })
      }

      if (rxIsUp) {
        rasPiStatus.rx.some((item) => {
          if (item._id === id) {
            rxTemperature = Number(item.temperature)
            // console.log(`rxTemperature ${id}: ${rxTemperature} Celsius.`)
            return true
          }

          return false
        })
      }

      return (
        <>
          <TemperatureStatus txTemperature={txTemperature} rxTemperature={rxTemperature}/>
        </>
      )
    }
  }

  const getRows = () => {
    return rasPiIdSelector.map((id) => {
      return (
        <tr key={id}>
          <td className='pt-2'>
            {id}
          </td>
          <td>
            <Table borderless>
              <tbody>
              <tr>
                <td>Tx</td>
              </tr>
              <tr>
                <td>Rx</td>
              </tr>
              </tbody>
            </Table>
          </td>
          <td>{getRasPiStatus(id, true)}</td>
          <td>{getImgStatus(id)}</td>
          <td>{getEnvTemperature(id)}</td>
          <td>{getRasPiTemperature(id)}</td>
        </tr>
      )
    })
  }

  return (
    <div className='StatusTable'>
      {
        userSelector &&
        // <Container>
          <Table className="mx-auto" size='sm' style={{maxWidth:'500px'}}>
            <thead>
            <tr>
              <th colSpan={2}>UNDP</th>
              <th>Current status</th>
              <th>Last image</th>
              <th>Env temp</th>
              <th>CPU temp</th>
            </tr>
            </thead>
            <tbody>
            {getRows()}
            </tbody>
          </Table>
        // </Container>
      }
    </div>
  )
}

export default StatusTable
