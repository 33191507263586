import React from 'react'
import { useSelector } from 'react-redux'
import UserActions from './UserActions'

const User = (props) => {
  const userSelector = useSelector((state) => {
    return state.userDb.users.find((user) => user._id === props._id)
  })

  return (
    <tr className='User'>
      <td>{userSelector.username}</td>
      <td>{userSelector.role}</td>
      {/* <td>{props._id}</td> */}
      <td>
        <UserActions
          _id={props._id}
          username={userSelector.username}
          role={userSelector.role}
          renderTrigger={props.renderTrigger}
          setRenderTrigger={(x) => props.setRenderTrigger(x)}
        />
      </td>
    </tr>
  )
}

export default User
