import React from 'react'
import { createStore, applyMiddleware } from 'redux'
import reducer from './redux/reducer'
import { Provider } from 'react-redux'
import thunk from 'redux-thunk'
import TopNavbar from './bars/TopNavbar'
import StatusBar from './bars/StatusBar'
import Main from './Main'

const App = () => {
  const store = createStore(reducer, applyMiddleware(thunk))

  return (
    <Provider store={store}>
      <div className='App'>

        <TopNavbar />
        <StatusBar />
        <Main />

      </div>
    </Provider>
  )
}

export default App
