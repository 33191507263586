import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Navbar from 'react-bootstrap/Navbar'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import SummaryHistogramWrapper from './SummaryHistogram/SummaryHistogramWrapper'
import HistogramWrapper from './Histogram/HistogramWrapper'
import IntervalDropdown from './IntervalDropdown'
import localStorageService from '../../services/localStorageService'

const Stats = () => {
  const BACKEND_SOCKET = process.env.REACT_APP_BACKEND_SOCKET || 'http://localhost:3001'

  const [interval, setInterval] = useState('daily')
  const [data, setData] = useState([])

  const filtersSelector = useSelector((state) => state.filters)

  const dispatch = useDispatch()

  useEffect(() => {
    (async () => {
      const filterString = `startDate=${filtersSelector.startDate}&endDate=${filtersSelector.endDate}&isLiked=${filtersSelector.isLiked}&isHidden=${filtersSelector.isHidden}&isHuman=${filtersSelector.isHuman}&isWeapon=${filtersSelector.isWeapon}&isWildlife=${filtersSelector.isWildlife}&isDomestic=${filtersSelector.isDomestic}&tag1=${filtersSelector.tag1}&tag2=${filtersSelector.tag2}&tag3=${filtersSelector.tag3}&rasPiId=${filtersSelector.rasPiId}`

      try {
        const res = await fetch(`${BACKEND_SOCKET}/api/graphs/graph_data?${filterString}`, {
          method: 'get',
          headers: {
            'content-type': 'application/json;charset=UTF-8',
            'Authorization': 'Bearer ' + localStorageService.getToken()
          }
        })
        if (res.status === 200) {
          const data = await res.json()

          // console.log("data begins")
          // console.log(data)
          // console.log("data ends.")

          setData(data.map((item) => ({ createdAt: new Date(item.createdAt) })))

          dispatch({
            type: 'QUERY_SUCCESS'
          })
        } else {
          dispatch({
            type: 'QUERY_ERROR'
          })
        }
      } catch (err) {
        dispatch({
          type: 'QUERY_ERROR'
        })
        console.log(`error: ${err}`)
      }
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtersSelector])

  const renderHistogram = () => {
    if (data.length === 0) {
      return 'No data to show. Please try editing the filters.'
    }
    return <HistogramWrapper data={data} interval={interval} />
  }
  const renderSummaryHistogram = () => {
    if (data.length === 0) {
      return 'No data to show. Please try editing the filters.'
    }

    return <SummaryHistogramWrapper data={data} />
  }

  return (
    <div className='Stats'>
      <Navbar bg='light'>
        <Navbar.Brand>{`The number of received pictures (Camera ${filtersSelector.rasPiId}) 
                from ${filtersSelector.startDate.toLocaleString('en-GB')} to ${filtersSelector.endDate.toLocaleString('en-GB')}`}
        </Navbar.Brand>
      </Navbar>
      <Row>
        <Col xs={12} md={4}><IntervalDropdown setInterval={(x) => setInterval(x)} /></Col>
      </Row>
      <Row>
        <Col xs={12}>{renderHistogram()}</Col>
      </Row>
      <Navbar bg='light'>
        <Navbar.Brand>{`Picture received pattern 
                from ${filtersSelector.startDate.toLocaleString('en-GB')} to ${filtersSelector.endDate.toLocaleString('en-GB')}`}
        </Navbar.Brand>
      </Navbar>
      <Row>
        <Col xs={12}>{renderSummaryHistogram()}</Col>
      </Row>
    </div>
  )
}

export default Stats
