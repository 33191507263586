import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import Badge from 'react-bootstrap/Badge'
import action from '../../../redux/action'

const Tags = (props) => {
  const [loading, setLoading] = useState(
    {
      isHuman: false,
      isWeapon: false,
      isWildlife: false,
      isDomestic: false
    }
  )

  const dispatch = useDispatch()

  const onTagClick = (key) => {
    setLoading({ ...loading, [key]: true })

    dispatch(action.setKey(key, props._id, !props.tag[key]))
      .then(() => setLoading({ ...loading, [key]: false }))
  }

  return (
    <span className='h5'>
      <Badge
        className={`mr-1 clickable ${(props.tag.isHuman ? 'tag-human' : '')}`}
        id='human'
        variant='secondary'
        onClick={() => onTagClick('isHuman')}
      >
        {
                    (loading.isHuman) ? <div className='lds-ellipsis'><div /><div /><div /><div /></div> : 'Human'
                }
      </Badge>
      <Badge
        className={`mr-1 clickable ${(props.tag.isWeapon ? 'tag-weapon' : '')}`}
        id='weapon'
        variant='secondary'
        onClick={() => onTagClick('isWeapon')}
      >
        {
                    (loading.isWeapon) ? <div className='lds-ellipsis'><div /><div /><div /><div /></div> : 'Weapon'
                }
      </Badge>
      <Badge
        className={`mr-1 clickable ${(props.tag.isWildlife ? 'tag-wildlife' : '')}`}
        id='wildlife'
        variant='secondary'
        onClick={() => onTagClick('isWildlife')}
      >
        {
                    (loading.isWildlife) ? <div className='lds-ellipsis'><div /><div /><div /><div /></div> : 'Wildlife'
                }
      </Badge>
      <Badge
        className={`mr-1 clickable ${(props.tag.isDomestic ? 'tag-domestic' : '')}`}
        id='domestic'
        variant='secondary'
        onClick={() => onTagClick('isDomestic')}
      >
        {
                    (loading.isDomestic) ? <div className='lds-ellipsis'><div /><div /><div /><div /></div> : 'Domestic'
                }
      </Badge>
    </span>
  )
}

export default Tags
