
import React from 'react'
import { useSelector } from 'react-redux'
import Row from 'react-bootstrap/Row'
import DropdownButton from 'react-bootstrap/DropdownButton'
import Dropdown from 'react-bootstrap/Dropdown'
import DatePicker, { registerLocale } from 'react-datepicker'
import th from 'date-fns/locale/th'
import gb from 'date-fns/locale/en-GB'

const LogFilters = (props) => {
  registerLocale('th', th)
  registerLocale('gb', gb)

  const queryRasPiArr = useSelector((state) => {
    const arr = []
    state.userDb.users.forEach((doc) => {
      if (doc.role === 'rasPi') {
        const rasPiId = Number(doc.username.replace('undp', '').replace('rx', ''))
        if (Number.isInteger(rasPiId)) {
          arr.push({ _id: doc._id, rasPiId: rasPiId })
        }
      }
    })
    // console.log(arr);
    return arr
  })
  return (
    <div>
      <Row>
        <p className='my-auto ml-1'>UNDP:</p>
        <DropdownButton
          id='dropdownFilterRasPiId'
          variant='light'
          size='sm'
          title={props.filters.rasPiId}
        >
          <Dropdown.Item onClick={() => props.onFilterRasPiIdClick('All')}>All</Dropdown.Item>
          {
                        queryRasPiArr.map((item) => <Dropdown.Item key={item._id} onClick={() => props.onFilterRasPiIdClick(`${item.rasPiId}`)}>{item.rasPiId}</Dropdown.Item>)
                    }
        </DropdownButton>
      </Row>

      <Row>
        <p className='my-auto ml-1'>Type:</p>
        <DropdownButton
          id='dropdownFilterType'
          variant='light'
          size='sm'
          title={props.filters.rasPiType}
        >
          <Dropdown.Item onClick={() => props.onFilterTypeClick('Tx+Rx')}>Tx+Rx</Dropdown.Item>
          <Dropdown.Item onClick={() => props.onFilterTypeClick('Tx')}>Tx</Dropdown.Item>
          <Dropdown.Item onClick={() => props.onFilterTypeClick('Rx')}>Rx</Dropdown.Item>
        </DropdownButton>
      </Row>

      <Row>
        <p className='my-auto ml-1'>Start date:</p>
        <DatePicker
          closeOnScroll={(e) => e.target === document}
          selected={props.filters.startDate}
                    // selectsStart
                    // startDate={props.filters.startDate}
                    // endDate={props.filters.endDate}
                    // minDate={props.filters.startDate}
          maxDate={props.filters.endDate}
                    // locale="th"
          locale='gb'
          showTimeSelect
          timeFormat='p'
          timeIntervals={60}
          dateFormat='Pp'
          showDisabledMonthNavigation
                    // disabledKeyboardNavigation
          onChange={(date) => props.onFilterStartDateClick(date)}
        />

        <p className='my-auto ml-1'>End date:</p>
        <DatePicker
          closeOnScroll={(e) => e.target === document}
          selected={props.filters.endDate}
                    // selectsEnd
                    // startDate={props.filters.startDate}
                    // endDate={props.filters.endDate}
          minDate={props.filters.startDate}
          maxDate={new Date().setSeconds(0, 0)}
                    // locale="th"
          locale='gb'
          showTimeSelect
          timeFormat='p'
          timeIntervals={60}
          dateFormat='Pp'
          showDisabledMonthNavigation
                    // disabledKeyboardNavigation
          onChange={(date) => props.onFilterEndDateClick(date)}
        />
      </Row>
    </div>
  )
}

export default LogFilters
