const SESSION_MINUTES = process.env.REACT_APP_SESSION_MINUTES || 60

const jwt = require('jsonwebtoken')

const setToken = (token) => {
  // console.log("SET TOKEN");
  localStorage.setItem('ACCESS_TOKEN', token)
}

const getToken = () => {
  // console.log("GET TOKEN");
  return localStorage.getItem('ACCESS_TOKEN')
}

const removeToken = () => {
  // console.log("REMOVE TOKEN");
  localStorage.removeItem('ACCESS_TOKEN')
}

const getUser = () => {
  const token = getToken()
  if (token) {
    const decoded = jwt.decode(token)

    const userObj = { username: decoded.username, role: decoded.role }
    return userObj
  }
  return null
}

const getExp = () => {
  // console.log("GET EXP");
  const token = getToken()
  if (token) {
    const decoded = jwt.decode(token)
    return decoded.exp
  }
  return 0
}

const getIat = () => {
  const token = getToken()
  if (token) {
    const decoded = jwt.decode(token)
    return decoded.iat
  }
  return 0
}

const getUserFromToken = () => {
  // changed exp validation to from token to calculate it from token's iat as we will ignore exp for rasPi

  const now = new Date()
  // const exp = getExp();
  const iat = getIat()

  // if (exp === 0) {        //no token exists
  //     return null;
  // }

  if (iat === 0) { // no token exists
    return null
  }

  const userObj = getUser()

  if (userObj.role === 'rasPi') { // ignore exp if the user.role is rasPi
    return userObj
  }

  // const expTime = new Date(exp * 1000);  //Javascript time unit is millisecond, whilst JWT time unit is second since 1 Jan 1970
  const expTime = new Date((iat + SESSION_MINUTES * 60) * 1000)

  if (now >= expTime) { // token expired
    removeToken()

    return null
  }

  return userObj
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  setToken,
  getToken,
  removeToken,
  getUser,
  getExp,
  getUserFromToken
}
